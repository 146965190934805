import { AppService } from '../AppService';

export const VendorService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getVendors: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/vendors",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Vendors', 'GetVendors'],
    }),
    getVendor: builder.query({
        query: (id) => ({
            url: `/vendors/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Vendors', 'GetVendor'],
    }),
    getVendorToUpdate: builder.query({
        query: (id) => ({
            url: `/vendors/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Vendors', 'GetVendorToUpdate'],
    }),
    createVendor: builder.mutation({
        query: (data = {}) => ({
            url: `/vendors/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Vendors', 'GetCargo'],
    }),
    deleteVendor: builder.mutation({
        query: (id) => ({
            url: `/vendors/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetVendors', 'GetCargo'],
    }),
  }),
});

export const {
    useGetVendorsQuery,
    useGetVendorQuery,
    useGetVendorToUpdateQuery,
    useCreateVendorMutation,
    useDeleteVendorMutation,
} = VendorService;
