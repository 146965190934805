/**
 * Common helper functions.
 */

import moment from "moment-timezone";

/**
 * Formats a number into comma-separated sections.
 * @param number {Number} Number to be formatted
 * @returns {String}
 */
export const numberFormat = (number: number | string, decimalPlaces: number = 0, showZeroDecimalPlaces: boolean = false): number | string => {
  number = (number && showZeroDecimalPlaces) ? parseFloat(number?.toString())?.toFixed(decimalPlaces): number;
  let parts = number?.toString().split(".");
  if(parts?.length){
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (parts.length > 1) {
      parts[1] = parts[1].slice(0, decimalPlaces);
      if(!(parts[1].length > 0 || decimalPlaces === 0)){//Be able to display . when typing it
        parts[0]  = `${parts[0]}.`;
      }
    }
    return parts.join(parts[1]?.length ? "." : "");
  }
  return number;
};

export const roundNumber = (number: number | string, decimalPlaces: number = 0): number | string => {
  return parseFloat(number?.toString())?.toFixed(decimalPlaces);
};

export const stripCommas = (number: any): any => {
  // number = number || 0;

  if(number){
    if (number) {
      number = String(number);
    }

    if (/^-*\d+,\d+/.test(number)) {
      return number.replace(/,/g, "");
    }
    if (/^-?(\d+\.?)?\d+$/.test(number)) {
      return number;
    }
  }

  return number;
};

export const decimalFormat = (number: any): any => {
  if (typeof number === "string") {
    if (number.includes(",")) {
      return number.replaceAll(",", "");
    }
  }
  return number;
};

/**
 * Rounds a `number` to `decimalPlaces`.
 * @param number {Number}
 * @param decimalPlaces {int}
 * @returns {Number}
 */
export const round = (number: any, decimalPlaces: number = 0): number => {
  // number = Math.round(number + "e" + decimalPlaces);
  number = Math.round(Number(number + "e" + decimalPlaces));
  return Number(number + "e" + -decimalPlaces);
};

export const padStart = (number: number | string | undefined, padLength: number, padString: string): string | undefined | 0 => {
  return number && number.toString().padStart(padLength, padString);
};

/**
 * Parses an integer from a `text`.
 * @param text {String}
 * @returns {Number}
 */
export const parseInteger = (text: string): number => {
  let results = /\d+/.exec(text || "");
  return results && results.length ? parseInt(results[0]) : 0;
};

/**
 * Strips all non-digit characters from `text` and returns a string of digits.
 * @param text {String}
 * @returns {String}
 */
export const validateInteger = (text: string): string => {
  text = text || "";
  return Array.prototype.slice
    .call(text)
    .filter((c) => /\d+/.test(c))
    .join("");
};

/**
 * Strips all non-digit characters from `text` and returns a string representative of a number.
 * @param text {String}
 * @returns {String}
 */
export const validateNumber = (text: string): string => {
  text = text || "";
  return Array.prototype.slice
    .call(text)
    .filter((c) => /[\d.\-]+/.test(c))
    .join("");
};

export const validateBP = (text: string): string => {
  text = text || "";
  return Array.prototype.slice
    .call(text)
    .filter((c) => /[\d/\-]+/.test(c))
    .join("");
};

/**
 * Capitalizes each first letter of each word in a `text`.
 * @param text {String}
 * @returns {String}
 */
export const capitalize = (text: string): string => {
  text = text || "";
  if (typeof text === "string") {
    return text
      .split(" ")
      .map((e) => e.charAt(0).toUpperCase() + e.substr(1).toLowerCase())
      .join(" ");

    // return text.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    
  }
  return "";
};

/**
 * Calls a `fn` after a specified `delay` time.
 * @param fn {Function}
 * @param delay {int}
 */
export const debounce = (fn: any, delay: number): any => {
  if (window.$debounceTimeoutID) {
    window.clearTimeout(window.$debounceTimeoutID);
  }

  window.$debounceTimeoutID = window.setTimeout(fn, delay);
};

/**
 * Returns an empty object if the argument is null.
 * @param object
 * @returns {*}
 */
export const requireNonNull = (object: any): object => (object ? object : {});

/**
 * Formats a date object or string to the format of year-month-date.
 * @param date
 * @returns {string}
 */
export const formatDateForDb = (date: Date | string): string => {
  if (typeof date === "string") date = new Date(date);
  return moment(date).format("YYYY-MM-DD");
};

/**
 * Formats a date object or string to the format of year-month-date hour:minute:second.
 * @param date
 * @returns {string}
 */
export const formatDateTimeForDb = (date: Date | string): string => {
  if (typeof date === "string") date = new Date(date);
  return moment(date).format("YYYY-MM-DD HH:mm:ss");
};

/**
 * Formats a date object or string to the format of hour:minute:second.
 * @param date
 * @returns {string}
 */
export const formatTimeForDb = (date: Date | string): string => {
  if (typeof date === "string") date = new Date(date);
  return moment(date).format("HH:mm:ss");
};

/**
 * Formats a date object or string to the format of yearh.
 * @param date
 * @returns {string}
 */
export const formatYearForDb = (date: Date | string): string => {
  if (typeof date === "string") date = new Date(date);
  return moment(date).format("YYYY");
};

/**
 * Formats a date object or string to the format of year-month.
 * @param date
 * @returns {string}
 */
export const formatYearMonthForDb = (date: Date | string): string => {
  if (typeof date === "string") date = new Date(date);
  return moment(date).format("YYYY-MM");
};

export const utcToLocalTime = (date: Date | string, format: string = "YYYY-MM-DD HH:mm:ss"): string => {
  return moment.utc(date).tz(moment.tz.guess()).format(format)
};

export const localTimeToUtc = (date: Date | string, format: string = "YYYY-MM-DD HH:mm:ss"): string => {
  return moment(date).utc().format(format)
};

/**
 * Returns an array of dates between two dates.
 * @param startDate
 * @param endDate
 * @returns {Array}
 */
export const getDaysBetweenDates = (startDate: Date | string, endDate: Date | string): string[] => {
  // let now = startDate.clone(),
  if (typeof startDate === "string") startDate = new Date(startDate);
  let now = moment(startDate),
    dates = [];

  while (now.isSameOrBefore(endDate)) {
    dates.push(now.format("YYYY-MM-DD"));
    now.add(1, "days");
  }

  return dates;
};

/**
 * Returns a random integer between min (inclusive) and max (inclusive).
 * The value is no lower than min (or the next integer greater than min
 * if min isn't an integer) and no greater than max (or the next integer
 * lower than max if max isn't an integer).
 * Using Math.round() will give you a non-uniform distribution!
 */
export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getValidationRules = () => {
  return {
    required: (value: any) => {
      if (typeof value === "string") {
        value = value.trim();
      }
      else if (Array.isArray(value)) {
        value = value.length;
      }

      return !!value || "This field is required.";
    },
    startsWithWordChar: (value: any) => {
      return /^\w+/.test(value) || "Invalid value.";
    },
    integer: (value: any) => {
      value = stripCommas(value);
      // return /^-?\d+$/.test(value) || "Invalid integer.";
      return (
        !value || /^-?\d+$/.test(value) || "Invalid integer."
      );
    },
    optionalInteger: (value: any) => {
      value = stripCommas(value);
      return !value || (value && /^-?\d+$/.test(value)) || "Invalid integer.";
    },
    number: (value: any) => {
      value = stripCommas(value);
      // return /^-?\d*\.?\d+$/.test(value) || "Invalid number.";
      return (
        !value || /^-?\d*\.?\d+$/.test(value) || "Invalid number."
      );
    },
    nonZero: (value: any) => {
      return value != 0 || "Zero not allowed.";
    },
    optionalNumber: (value: any) => {
      return (
        !value || (value && /^-?\d*\.?\d+$/.test(value)) || "Invalid number."
      );
    },
    email: (value: any) => {
      return (
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          value
        ) || "Invalid Email."
      );
    },
    phone: (value: any) => {
      return value !== "0752043246"
        ? /^0\d{9}$/.test(value) || "Invalid phone."
        : "Invalid phone.";
    },
    optionalPhone: (value: any) => {
      return  !value || (value && /^0\d{9}$/.test(value)) || "Invalid phone.";
    },
    ipaddress: (value: any) => {
      return (
        (value &&
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            value
          )) ||
        "Invalid ip-address."
      );
    },
    optionalIP: (value: any) => {
      return (
        !value ||
        (value &&
          /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            value
          )) ||
        "Invalid ip-address."
      );
    },
    password: (value: any, invalidMessage: any = undefined) => {
      var password_formats = [/^[A-Za-z]\w{7,14}$/, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/, /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/, /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/];
      var password_formats_messages = ["Password must be between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter", "Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter", "Password must be between 7 to 15 characters which contain at least one numeric digit and a special character", "Password must be between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character"];
      return password_formats[1].test(value) || (invalidMessage || password_formats_messages[1]);
    },
    url: (value: string, invalidMessage: any = undefined) => {
      var url_formats = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
      '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
      return !!url_formats.test(value);
    }
  };
};

export const getAge = (date: Date | string): string | null => {
  if (!date || date === "0000-00-00") {
    return null;
  }

  const years = moment().diff(date, "years");
  const months = moment().diff(date, "months");
  const days = moment().diff(date, "days");

  if (years >= 1) {
    return Math.floor(years) + " years";
  }

  if (months >= 1 && months <= 12) {
    return Math.floor(months) + " months";
  }

  return days.toString() !== "NaN" ? days + " days" : null;
};

export const getBirthDate = (age: number | string, ageUnit: any): string | null => {
  let ageUnits = ["year", "years", "month", "months", "week", "weeks", "day", "days"];
  ageUnit = ageUnit && ageUnit.toLowerCase();
  ageUnit = ageUnits.includes(ageUnit) ? ageUnit : "years";
  let date = moment(new Date()).subtract(age, ageUnit).format("YYYY-MM-DD");

  return age ? date : null;
};

export const getAgeInYears = (date: Date | string): number => {
  if (!date || date === "0000-00-00") {
    return 0;
  }

  return moment().diff(date, "years");
};

export const getDetailedAge = (dateFrom: Date | string): string | null => {
  if (!dateFrom || dateFrom === "0000-00-00") {
    return null;
  }

  let date = moment(new Date(dateFrom));
  const years = moment().diff(date, "years");
  date.add(years, "years");

  const months = moment().diff(date, "months");
  date.add(months, "months");
  const days = moment().diff(date, "days");
  return `${years} Years, ${months} Months, ${days} Days`;
};

export const getDateDiffInDays = (date1: Date | string, date2: Date | string): number => {
  if (!date1 || !date2 || date1 !== "0000-00-00" || date2 !== "0000-00-00") {
    return 0;
  }

  return moment(date1).diff(date2, "days");
};

export const getDateDiffInMonths = (date1: Date | string, date2: Date | string): number => {
  if (!date1 || !date2 || date1 !== "0000-00-00" || date2 !== "0000-00-00") {
    return 0;
  }

  return moment(date1).diff(date2, "months");
};

export const getDateTimeDiff = (from_date: Date | string, to_date: Date | string = moment().format("YYYY-MM-DD HH:mm:ss")): string | null => {
  let valid_start_date = moment(from_date, ["YYYY-MM-DD HH:mm:ss"], true).isValid();
  let valid_end_date = moment(to_date, ["YYYY-MM-DD HH:mm:ss"], true).isValid();
  if (!valid_start_date || !valid_end_date || !from_date || !to_date || from_date === "0000-00-00 00:00:00" || to_date === "0000-00-00 00:00:00") {
    return null;
  }

  const years = moment(to_date).diff(from_date, "years");
  const months = moment(to_date).diff(from_date, "months");
  const days = moment(to_date).diff(from_date, "days");
  let hours = moment(to_date).diff(from_date, "hours");
  let minutes = moment(to_date).diff(from_date, "minutes");
  let seconds = moment(to_date).diff(from_date, "seconds");

  if (years >= 1) {
    return Math.floor(years) + " years";
  } else if (months >= 1) {
    return Math.floor(months) + " months";
  } else if (days >= 1) {
    return Math.floor(days) + " days";
  }

  hours = Math.floor(hours);
  minutes = Math.floor(((minutes / 60) - Math.floor(minutes / 60)) * 60);
  seconds = Math.floor(((seconds / 60) - Math.floor(seconds / 60)) * 60);

  return (hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + ":" + (seconds < 10 ? ("0" + seconds) : seconds);
};

export const getDateTimeDiffInMinutes = (from_date: Date | string, to_date: Date | string = moment().format("YYYY-MM-DD HH:mm:ss")): number | null => {
  let valid_start_date = moment(from_date, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"], true).isValid();
  let valid_end_date = moment(to_date, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"], true).isValid();
  if (!valid_start_date || !valid_end_date) {
    return null;
  }

  return Math.floor(moment(to_date).diff(from_date, "minutes"));
};

export const reportErrors = (alert: any, errorBody: any): any => {
  let message = "Something went wrong.";
  if (errorBody?.message === "canceled") {
    return alert?.close();
  }
  if (errorBody.response) {
    const statusCode = parseInt(errorBody.response.status);
    switch (statusCode) {
      case 401: {
        message = "You were logged out.";
      }
        break;
      case 403: {
        let data = errorBody.response.data;
        if (data.error) {
          message = data.error;
        }
      }
        break;
      case 404: {
        message = "The requested resource was not found.";
      }
        break;
      case 422: {
        // validation errors
        let data = errorBody.response.data;
        if (data.error) {
          message = data.error;
        } else {
          let errors: any[] = [];
          Object.keys(data).forEach((e, i) => errors.push(data[e][0]));
          message = errors.join("\n");
        }
      }
        break;
    }
  } else if (errorBody.request) {
    message = "Network connectivity error.";
  }

  if (alert) {
    alert.showError(message);
  }
};

// Validates a date, checks if date is valid
export const validateDateTimeInput = (date: string, format: string = "YYYY-MM-DD HH:mm:ss"): boolean => {
  return moment(date, format, true).isValid();
};

export const validateDateInput = (date: string, format:string = "YYYY-MM-DD"): boolean => {
  return moment(date, format, true).isValid();
};

// Get valid date or return null
export const getValidDateOrNull = (date: string, format: string[] = ["YYYY-MM-DD"]): Date | null => {
  return moment(date, format, true).isValid()
    ? new Date(moment(date).toString())
    : null;
};
export const getValidDateTimeOrNull = (
  date: string,
  format: string[] = ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD HH:mm"]
): Date | null => {
  return moment(date, format, true).isValid()
    ? new Date(moment(date).toString())
    : null;
};

export const getValidTimeOrNull = (time: string, format: string[] = ["HH:mm:ss", "HH:mm"]): Date | null => {
  let date = `${moment().format("YYYY-MM-DD")} ${time}`;
  return moment(time, format, true).isValid()
    ? new Date(moment(date).toString())
    : null;
};

export const getValidYearOrNull = (year: string, format: string[] = ["YYYY"]): Date | null => {
  let date = `${year}-01-01`;
  return moment(year, format, true).isValid()
    ? new Date(moment(date).toString())
    : null;
};

export const toWords = (s: any): string => {
  let thousands = ["", "thousand", "million", "billion", "trillion"];
  let digit = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
  ];
  let tens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
  ];
  let tw_val = [
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];
  s = s.toString();
  s = s.replace(/[\, ]/g, "");

  if (s != parseFloat(s)) return "not a number ";
  let int_len = s.indexOf(".");
  if (int_len == -1) int_len = s.length;
  if (int_len > 15) return "too big";
  let int_arr = s.split("");
  let str_val = "";
  let sk_val = 0;
  for (let i = 0; i < int_len; i++) {
    if ((int_len - i) % 3 == 2) {
      if (int_arr[i] == "1") {
        if (int_len > 2 && str_val.length)
          str_val += "and " + tens[Number(int_arr[i + 1])] + " ";
        else str_val += tens[Number(int_arr[i + 1])] + " ";
        i++;
        sk_val = 1;
      } else if (int_arr[i] != 0) {
        if (int_len > 2 && str_val.length)
          str_val += "and " + tw_val[int_arr[i] - 2] + " ";
        else str_val += tw_val[int_arr[i] - 2] + " ";
        sk_val = 1;
      }
    } else if (int_arr[i] != 0) {
      str_val += digit[int_arr[i]] + " ";
      if ((int_len - i) % 3 == 0) str_val += "hundred ";
      sk_val = 1;
    }
    if ((int_len - i) % 3 == 1) {
      if (sk_val) str_val += thousands[(int_len - i - 1) / 3] + " ";
      sk_val = 0;
    }
  }
  if (int_len != s.length) {
    let y_val = s.length;
    str_val += "point ";
    for (let i = int_len + 1; i < y_val; i++)
      str_val += digit[int_arr[i]] + " ";
  }
  return str_val.replace(/\s+/g, " ");
};

export const truncate = (input: string): string => {
  if (input?.length > 9) {
    return input.substring(0, 9) + "...";
  }
  return input;
};

export const parseXml = (text: any): Document | undefined => {
  if (typeof text === "string") {
    const parser = new window.DOMParser();
    return parser.parseFromString(text, "text/xml");
  }
};

export const hasPermission = (permission?: any, permissions: any[] = []) => {
  return permissions?.find(permissn => permissn.permission === permission) ? true : false;
}

export const getNoPermissionMessage = (action?: string, item?: string, prefixModule?: string, sufix?: string): string => {
  let modifiedItem = item && capitalize((prefixModule ? item?.replace(new RegExp(`${prefixModule}_`), "") : item).replace(/_/g, " "));
  return `
    You have no permission${action ? " to " : ""}${action || ""}${item ? " " : ""}${modifiedItem || ""}
    ${sufix ? " " : ""}${capitalize(sufix || "")}.
  `;
}

export const getModulePermissions = (module: any, permissions: any[] = []) => {
  let modulePermissions = ['add', 'update', 'delete', 'view', 'print', 'export'];
  let modulePermissionsObject: any = {};
  modulePermissions.forEach((permission: any) => {
    modulePermissionsObject[permission] = hasPermission(`${module}_${permission}`, permissions)
  })

  return modulePermissionsObject;
}

export const AuthenticateRoutes = (routes: any[], permissions: any[] = [], noAccessComponent: any) => {
  return routes.map((route: any) => ({
    ...route,
    component: !route.permission || hasPermission(route.permission, permissions) ? route.component : noAccessComponent,
  }))
}

export const AuthenticateMenu = (menu: {[rest: string]: any}, permissions: any[] = [], onNoAccess: (permission: string) => void) => {
  return {
    ...menu,
    items: menu?.items?.map((item: any) => {
      let authorized = !item.permission || hasPermission(item.permission, permissions);
      return {
        ...item,
        disabled: !authorized,
        onClick: !authorized ? () => onNoAccess(item.permission) : undefined,
        subMenu: item.subMenu?.map((menu: any) => {
          let authorized = !menu.permission || hasPermission(menu.permission, permissions);
          return {
            ...menu,
            disabled: !authorized,
            onClick: !authorized ? () => onNoAccess(menu.permission) : undefined,
          }
        })
      }
    })
  }
}

export const getDatesOptions = (): {name: string, label: string, from_date: any, to_date: any, [rest: string]: any}[] => {
  return [
    {name: "today", label: "Today", from_date: moment().startOf("day").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")},
    {name: "this_week", label: "This Week", from_date: moment().startOf("week").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().endOf("week").format("YYYY-MM-DD HH:mm:ss")},
    {name: "this_month", label: "This Month", from_date: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss")},
    {name: "this_quarter", label: "This Quarter", from_date: moment().startOf("quarter").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().endOf("quarter").format("YYYY-MM-DD HH:mm:ss")},
    {name: "this_year", label: "This Year", from_date: moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().endOf("year").format("YYYY-MM-DD HH:mm:ss")},
    {name: "yesterday", label: "Yesterday", from_date: moment().subtract(1, "days").startOf("day").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().subtract(1, "days").endOf("day").format("YYYY-MM-DD HH:mm:ss")},
    {name: "previous_week", label: "Previous Week", from_date: moment().subtract(1, "weeks").startOf("week").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().subtract(1, "weeks").endOf("week").format("YYYY-MM-DD HH:mm:ss")},
    {name: "previous_month", label: "Previous Month", from_date: moment().subtract(1, "months").startOf("month").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().subtract(1, "months").endOf("month").format("YYYY-MM-DD HH:mm:ss")},
    {name: "previous_quarter", label: "Previous Quarter", from_date: moment().subtract(1, "quarters").startOf("quarter").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().subtract(1, "quarters").endOf("quarter").format("YYYY-MM-DD HH:mm:ss")},
    {name: "previous_year", label: "Previous Year", from_date: moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD HH:mm:ss"), to_date: moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD HH:mm:ss")},
    {name: "custom", label: "Custom", from_date: "custom", to_date: "custom"},
  ]
};
