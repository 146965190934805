import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Form } from "../../../../components/form";

import { useDispatch, useSelector } from "react-redux";
import { useGetChargesQuery } from "../../../../services/settings/general/ChargeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { getValidationRules, numberFormat, stripCommas } from "../../../../utils/helpers/helpers";
import { useCreateGeneralConfigMutation } from "../../../../services/settings/general/GeneralConfigService";
import { SelectInput, TextField } from "../../../../components/input";
import { ChargeList } from "../../general";
interface DiscountAndChargesProps {
  exportTitle?: string,
  [rest: string]: any,
}

const DiscountAndCharges = (props: DiscountAndChargesProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 
  const form = useRef<any>();
  const chargeForm = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [ createConfig ] = useCreateGeneralConfigMutation();
  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetChargesQuery(filters);
  const [configuredTaxes, setConfiguredTaxes] = useState([]);
  const [configuredCharges, setConfiguredCharges] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [chargeList, setChargeList] = useState([]);

  const [discountTypes] = useState<any[]>([
    {label: "Percent", value: "percent"},
    {label: "Fixed", value: "fixed"},
  ]);

  const saveDiscount = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Discount"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        let FDT = {
          configs: {
            discount: FD.discount,
            discount_type: FD.discount_type,
          }
        }
        createConfig({...FDT})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Discount has successfully saved", unreplaceable: true}));
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const saveCharges = () => {
    if(!chargeForm?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save Charge(s)"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD = {
          configs: {
            charges: configuredTaxes.concat(configuredCharges)?.map((charge: any) => charge.charge_id)?.join(",") || "",
          }
        }
        createConfig({...FD})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Charge(s) successfully saved", unreplaceable: true}));
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  useEffect(() => {
    let config = user?.configuration;
    config?.charges && setConfiguredTaxes(
      data?.charges?.filter((charge: any) => config?.charges?.split(",")?.includes(charge.charge_id) && charge.category === "tax")
      ?.map((charge: any) => ({label: charge.name, value: charge.charge_id, ...charge}))
    );
    config?.charges && setConfiguredCharges(
      data?.charges?.filter((charge: any) => config?.charges?.split(",")?.includes(charge.charge_id) && charge.category === "other")
      ?.map((charge: any) => ({label: charge.name, value: charge.charge_id, ...charge}))
    );
    data?.charges && setTaxList(data?.charges?.filter((charge: any) => charge.category === "tax")?.map((charge: any) => ({label: charge.name, value: charge.charge_id, ...charge})));
    data?.charges && setChargeList(data?.charges?.filter((charge: any) => charge.category === "other")?.map((charge: any) => ({label: charge.name, value: charge.charge_id, ...charge})));
  }, [data, user])

  useEffect(() => {
    let config = user?.configuration;
    config && setFormData({
      discount: config.discount,
      discount_type: config.discount_type,
    })
  }, [user])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header bg-white">Default Trips Discount</div>
            <div className="card-body">
              <div>
                <Form ref={form}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <SelectInput
                        ref={inputs[0]}
                        label="Discount Type"
                        block={true}
                        clearable
                        options={discountTypes}
                        value={discountTypes.filter(discountType => discountType.value === formData.discount_type)}
                        onChange={(value) => setFormData({
                          ...formData,
                          discount_type: value?.value
                        })}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <TextField
                        ref={inputs[1]}
                        label="Discount Rate/Amount"
                        block={true}
                        rules={[inputValidation.number]}
                        value={numberFormat(formData.discount) || ""}
                        onChange={(value) => setFormData({
                          ...formData,
                          discount: stripCommas(value)
                        })}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="card-footer d-flex flex-row">
              <button
                className="btn bg-secondary"
                onClick={() => saveDiscount()}
              >
                Save Discount
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <div className="card">
            <div className="card-header bg-white">Default Trips Charges</div>
            <div className="card-body">
              <div>
                <Form ref={chargeForm}>
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <SelectInput
                        ref={inputs[0]}
                        label="Taxes"
                        block={true}
                        isMulti
                        options={taxList}
                        value={configuredTaxes}
                        onChange={(value) => setConfiguredTaxes(value)}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <SelectInput
                        ref={inputs[1]}
                        label="Other Charges"
                        block={true}
                        isMulti
                        options={chargeList}
                        value={configuredCharges}
                        onChange={(value) => setConfiguredCharges(value)}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
            <div className="card-footer d-flex flex-row">
              <button
                className="btn bg-secondary"
                onClick={() => saveCharges()}
              >
                Save Charges
              </button>
            </div>
          </div>
        </div>
        <div className="col-12 mt-5">
          <div className="card">
            <div className="card-body">
              <ChargeList
                accountCategory="income"
                title="Available Trip Charges"
              />
            </div>
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default DiscountAndCharges;
