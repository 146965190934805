import { AppService } from '../AppService';

export const JournalEntryService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getJournalEntries: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/accounting/journal-entries",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'JournalEntries', 'GetJournalEntries'],
    }),
    getJournalEntryToUpdate: builder.query({
        query: (id) => ({
            url: `/accounting/journal-entries/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'JournalEntries', 'GetJournalEntryToUpdate'],
    }),
    createJournalEntry: builder.mutation({
        query: (data = {}) => ({
            url: `/accounting/journal-entries/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['JournalEntries'],
    }),
    deleteJournalEntry: builder.mutation({
        query: (id) => ({
            url: `/accounting/journal-entries/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetJournalEntries'],
    }),
  }),
});

export const {
    useGetJournalEntriesQuery,
    useGetJournalEntryToUpdateQuery,
    useCreateJournalEntryMutation,
    useDeleteJournalEntryMutation,
} = JournalEntryService;
