import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

export const FiltersState = createSlice({
  name: 'filtersState',
  initialState: {
    accounting_method: "accrual",
    annually_report_date_name: "today",
    annually_report_from_date: moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
    annually_report_to_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    monthly_report_date_name: "this_month",
    monthly_report_from_date: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
    monthly_report_to_date: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
    from_date: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
    to_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
    page: 1,
    limit: 10,
  },
  reducers: {
    setFilters: (state, action) => {
      return {...state, ...action.payload, ...(!action.payload?.page ? {page: 1} : {})};
    },
    clearFilters: (state: any) => {
      return {
        accounting_method: "accrual",
        annually_report_date_name: "today",
        annually_report_from_date: moment().startOf("year").format("YYYY-MM-DD HH:mm:ss"),
        annually_report_to_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        monthly_report_date_name: "this_month",
        monthly_report_from_date: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        monthly_report_to_date: moment().endOf("month").format("YYYY-MM-DD HH:mm:ss"),
        from_date: moment().startOf("month").format("YYYY-MM-DD HH:mm:ss"),
        to_date: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
        page: 1,
        limit: 10,
      };
    }
  },
});

// this is for dispatch
export const { setFilters, clearFilters } = FiltersState.actions;

// this is for configureStore
export default FiltersState.reducer;
