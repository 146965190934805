import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../components/form";
import {
  DateInput,
  FileInput,
  SelectInput,
  TextArea,
  TextField
} from "../../components/input";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { formatDateForDb, getValidDateOrNull, numberFormat, stripCommas } from "../../utils/helpers/helpers";
import { getValidationRules } from '../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreatePaymentMutation, useGetPaymentToUpdateQuery } from "../../services/payments/PaymentService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { useGetAccountsQuery } from "../../services/accounting/AccountService";

interface PaymentCreateProps {
  paymentID?: any,
  tripID?: any,
  paymentTransaction?: any,
  from?: string,
  modal?: MutableRefObject<any>,
}

const PaymentCreate = (props: PaymentCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetPaymentToUpdateQuery(props.paymentID || -1);
  const [ createPayment ] = useCreatePaymentMutation();

  const { data: accounts } = useGetAccountsQuery({});

  const [accountList, setAccountList] = useState<any>([]);
  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Payment Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
          if(formData[key]){
            FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
          }
        });
        let FData = new FormData();
        Object.keys(FD).forEach((key) => {
          FData.append(key, FD[key]);
        });
        createPayment({data: FData, id: props.paymentID})
        .unwrap()
        .then((response: any) => {
          if (props.paymentID) {
            dispatch(setAlert({type: "success", message: "Payment Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Payment has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.payment,
      payment_method: data.payment?.payment_method_id,
      trip: data.expense?.trip_id || props.tripID,
      payment_transaction_id: props.paymentTransaction?.payment_transaction_id,
      amount: data.payment?.amount || (props.paymentTransaction?.payment_amount_due > 0 ? props.paymentTransaction?.payment_amount_due : undefined),
    });
    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id})));
  }

  useEffect(() => {
    accounts?.accounts?.length && setAccountList(accounts?.accounts?.filter((account: any) => ["1", "2"].includes(account.account_type_id))
      ?.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
  }, [accounts])

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  let attachmentUrlParts = typeof formData.supporting_doc?.split === "function" && formData.supporting_doc?.split("/");
  let attachmentName = attachmentUrlParts.length && attachmentUrlParts[attachmentUrlParts.length-1];

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[0]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Payment Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.payment_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        payment_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[1]}
                    label="Payment Method"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={paymentMethods}
                    value={paymentMethods.filter(paymentMethod=> paymentMethod.value === formData.payment_method)}
                    onChange={(value) => setFormData({
                      ...formData,
                      payment_method: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[2]}
                    label="Amount"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={numberFormat(formData.amount) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      amount: stripCommas(value)
                    })}
                  />
                </div>
                {(props.paymentTransaction && props.from !== "trip") &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Deposit To"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountList}
                    value={accountList.filter((account: any) => account.value == formData.deposit_account)}
                    onChange={(value) => setFormData({
                      ...formData,
                      deposit_account: value?.value,
                      deposit_account_category: value?.account_category_id
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <FileInput
                    label="Attachment"
                    placeholder={attachmentName || "Chose file..."}
                    block={true}
                    onChange={(value) => setFormData({
                      ...formData,
                      supporting_doc: value && value[0]
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    ref={inputs[3]}
                    label="Comment"
                    block={true}
                    value={formData.comment}
                    onChange={(value) => setFormData({
                      ...formData,
                      comment: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.paymentID ? "Save" : "Add Payment"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default PaymentCreate;
