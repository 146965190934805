import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  SelectInput,
  TextField
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules, numberFormat, stripCommas } from '../../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateChargeMutation, useGetChargeToUpdateQuery } from "../../../../services/settings/general/ChargeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";

interface ChargeCreateProps {
  chargeID?: any,
  accountCategory?: string,
  modal?: MutableRefObject<any>,
}

const ChargeCreate = (props: ChargeCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const [accountTypes, setAccountTypes] = useState<any[]>([]);
  const { data, isLoading, isSuccess, isError, error } = useGetChargeToUpdateQuery(props.chargeID || -1);
  const [ createCharge ] = useCreateChargeMutation();

  const [chargeTypes, setChargeTypes] = useState<any[]>([
    {label: "Percent", value: "percent"},
    {label: "Fixed", value: "fixed"},
  ]);

  const [chargeCategories, setChargeCategories] = useState<any[]>([
    {label: "Tax", value: "tax"},
    {label: "Other", value: "other"},
  ]);

  const [taxAccountTypes] = useState<any[]>([
    {label: "Trip Related", value: "5", account_type_id: "5", account_category_id: "1"},
    {label: "Other", value: "21", account_type_id: "21", account_category_id: "5"},
  ]);

  const [otherAccountTypes] = useState<any[]>([
    {label: "Trip Related", value: "20", account_type_id: "20", account_category_id: "5"},
    {label: "Other", value: "21", account_type_id: "21", account_category_id: "5"},
  ]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Charge Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let formDt = {
          ...formData,
          account_category: props.accountCategory,
        }
        let FD: any = {};
        Object.keys(formDt).forEach((key) => {
         FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
        });
        createCharge({...FD, id: props.chargeID})
        .unwrap()
        .then((response: any) => {
          if (props.chargeID) {
            dispatch(setAlert({type: "success", message: "Charge Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Charge has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const reloadAccountTypes = (chargeCategory: any) => {
    setAccountTypes(chargeCategory === "tax" ? taxAccountTypes : otherAccountTypes);
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.charge,
    });
  }

  useEffect(() => {
    reloadAccountTypes(formData.category);
  }, [formData.category])

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Charge Category"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={chargeCategories}
                    value={chargeCategories.filter(chargeCategory => chargeCategory.value === formData.category)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        category: value?.value
                      });
                    }}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Charge Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Charge Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={chargeTypes}
                    value={chargeTypes.filter(chargeType => chargeType.value === formData.type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[2]}
                    label="Charge Rate/Amount"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required, inputValidation.number]}
                    value={numberFormat(formData.amount) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      amount: stripCommas(value)
                    })}
                  />
                </div>
                {props.accountCategory === "expense" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Expense Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountTypes}
                    value={accountTypes.filter(type => type.value === formData.account_type_id)}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_type_id: value?.value,
                      account_category_id: value?.account_category_id,
                    })}
                  />
                </div>}
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.chargeID ? "Save" : "Add Charge"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ChargeCreate;
