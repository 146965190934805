import {
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { capitalize } from "../../utils/helpers/helpers";

const PDFDetails = (props: any) => {
  // const { item, fields, columns } = props || {};

  // return (
  //   <View style={[styles.text, {width: "100%"}]}>
  //     {fields?.map((field: any, index: number) => (
  //       <view style={{width: `${columns/12 * 100}%`, textAlign: "right"}}>
  //         <view style={{width: "50%"}}><Text>{capitalize(field.label)}:</Text></view>
  //         <view style={{width: "50%"}}><Text>{capitalize(field.getValue ? field.getValue(item, index, field.name) : (item ? item[field.name] : ""))}</Text></view>
  //       </view>
  //     ))}
  //   </View>
  // )

  const columns = props.columns || 2;
  const fieldsRows = (props.fields || [])
    .filter((e: any) => typeof e.show === "undefined" || e.show)
    .reduce(
      (a: any, e: any, i: number) => (i % columns ? a[a.length - 1].push(e) : a.push([e]), a),
      []
    );

  return props.fields && props.fields.length ? (
    <View style={[props.style]}>
      {fieldsRows.map((fieldsRow: any, i: number) => (
        <View style={styles.row} key={i}>
          {fieldsRow.map((field: any, j: number) => (
            <View style={[styles.row, {flex: 1, marginBottom: 6, textAlign: j === fieldsRow.length - 1 ? "right" : undefined},]} key={j}>
              <Text style={[styles.text, {flex: 1, fontWeight: "bold", padding: 1}]}>{field.label}:</Text>
              <Text style={[styles.text, {width: j === fieldsRow.length - 1 ? "25%" : "75%", padding: 1} ]}>{field.getValue ? field.getValue(props.item, j, field.name) : (props.item ? props.item[field.name] : "")}</Text>
            </View>
          ))}
        </View>
      ))}
    </View>
  ) : null;
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default PDFDetails;
