import React from "react";
import { Image, Text, View, StyleSheet, Font } from "@react-pdf/renderer";

import banner from "../../assets/images/logo.jpeg";
import { capitalize, formatDateForDb, getAge } from "../../utils/helpers/helpers";
import { baseUrl } from "../../utils/constants/constants";

// import fontItalic from "../../fonts/Custom-Italic.ttf"
// import fontRegular from "../../fonts/Custom-Regular.ttf";
// import fontBold from "../../fonts/Custom-Bold.ttf";

// Font.register({
//   family: "Custom",
//   fonts: [
//     // { src: fontRegular },
//     // { src: fontItalic, fontStyle: "italic" },
//     // { src: fontBold, fontWeight: 700 },
//   ],
// });

interface HeaderDetailsProps {
  headerDetails?: any[],
}

interface PatientDetailsProps {
  patient?: any,
  style?: any,
}

const HeaderDetails = (props: HeaderDetailsProps) => {
  return (
    <View style={{ width: "100%", marginTop: "10pt" }}>
      <View style={styles.row}>
        {props.headerDetails?.map((val: any, index: number) => (
          <View
            key={index}
            style={
              (styles.descriptionsItem,
              { flexDirection: "row", marginRight: 10, marginBottom: 7 })
            }
          >
            <Text style={[styles.descriptionsItemLabel, { fontSize: "12px" }]}>
              {val.label}:
            </Text>
            <Text
              style={[
                styles.descriptionsItemValue,
                { marginLeft: 3, fontSize: "12px" },
              ]}
            >
              {val.value}
            </Text>
          </View>
        ))}
      </View>
    </View>
  );
};

const PatientDetails = (props: PatientDetailsProps) => {
  const [packageName, setPackageName] = React.useState();

  // const loadPackage = () => {
  //   let url = `${baseUrl}api/reception/sponsor-package/?ehms=v5`;
  //   url += `&Sponsor_ID=${props.patient?.Sponsor_ID}`;
  //   url += `&Registration_ID=${props.patient?.Registration_ID}`;

  //   window.axios
  //     .get(url)
  //     .then((response) => {
  //       setPackageName(response.data?.data);
  //     })
  //     .catch((error) => {});
  // };

  // React.useEffect(() => {
  //   if (patient) {
  //     loadPackage();
  //   }
  // }, [patient]);

  return props.patient ? (
    <View style={[{ width: "100%" }, props.style]}>
      <View style={styles.row}>
        <View style={styles.descriptionsItem}>
          <Text style={[styles.descriptionsItemLabel]}>Patient Name:</Text>

          <Text style={[styles.descriptionsItemValue]}>
            {`${capitalize(props.patient.first_name)} ${capitalize(props.patient.last_name)}`}
          </Text>
        </View>
        <View style={styles.descriptionsItem}>
          <Text style={[styles.descriptionsItemLabel]}>Patient Number:</Text>

          <Text style={[styles.descriptionsItemValue]}>
            {props.patient.registration_number}
          </Text>
        </View>
        <View style={styles.descriptionsItem}>
          <Text style={[styles.descriptionsItemLabel]}>Age:</Text>
          <Text style={[styles.descriptionsItemValue]}>
            {props.patient.birth_date ? getAge(props.patient.birth_date) : ""}
          </Text>
        </View>
        <View style={styles.descriptionsItem}>
          <Text style={[styles.descriptionsItemLabel]}>Gender:</Text>
          <Text style={[styles.descriptionsItemValue]}>{props.patient.sex}</Text>
        </View>

        <View style={styles.descriptionsItem}>
          <Text style={[styles.descriptionsItemLabel]}>Sponsor:</Text>

          <Text style={[styles.descriptionsItemValue]}>
            {props.patient.sponsor}
          </Text>
        </View>
        {packageName ? (
          <View style={styles.descriptionsItem}>
            <Text style={[styles.descriptionsItemLabel]}>Package:</Text>
            <Text style={[styles.descriptionsItemValue]}>{packageName}</Text>
          </View>
        ) : null}
      </View>
    </View>
  ) : null;
};

const styles = StyleSheet.create({
  descriptionsItem: {
    width: "20%",
    alignItems: "center",
    textAlign: "center",
  },
  row: {
    flexDirection: "row",
    marginBottom: 4,
    flexWrap: "wrap",
    alignItems: "center",
  },
  descriptionsItemLabel: {
    fontSize: 9,
    // fontFamily: "Custom",
    fontWeight: "bold",
  },
  descriptionsItemValue: {
    fontSize: 9,
    // fontFamily: "Custom",
    marginLeft: 2,
    display: "flex",
    flexWrap: "wrap",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  lightBlue: {
    // background: "linear-gradient(#f5f5f5, rgb(237, 241, 250))",
    // backgroundColor: "#e8effc",
  },
});

export default function Header(props: any) {
  const savedBanner = window.banner;

  let title = props.componentTitle ? (
    <View
      style={[
        {
          fontSize: 12,
          fontWeight: "bold",
          marginTop: "10pt",
          textTransform: "uppercase",
          borderTop: "1pt solid black",
          paddingTop: "10pt",
          paddingBottom: "2pt",
          textAlign: "center",
        },
      ]}
    >
      <props.componentTitle />
    </View>
  ) : (
    <Text
      style={[
        props.titleTextStyle,
        {
          fontSize: 12,
          fontWeight: "bold",
          marginTop: "10pt",
          textTransform: "uppercase",
          borderTop: "1pt solid black",
          paddingTop: "10pt",
          paddingBottom: "2pt",
          textAlign: "center",
        },
      ]}
    >
      {props.title}
    </Text>
  );

  return (
    <View style={[{ width: "100%" }]}>
      {props.sharedRecord ? (
        <View
          style={{
            width: "100%",

            paddingHorizontal: 18,
            paddingVertical: 20,
            border: "1px dashed #666",
            flexDirection: "row",
            alignItems: "center",
            minHeight: 60,
          }}
        >
          <Text
            style={[
              styles.text,
              {
                // display: "block",
                color: "#666",
                fontSize: 18,
                borderWidth: 0,
                textTransform: "uppercase",
                borderRightWidth: 1,
                flex: 1.5,
                fontWeight: "bold",
              },
            ]}
          >
            Facility Shared Records
          </Text>
          <View style={{ flex: 1 }}>
            <Text
              style={[
                styles.text,
                {
                  // display: "block",
                  textAlign: "right",
                  fontWeight: "semibold",
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  marginBottom: 2,
                  textTransform: "uppercase",
                  fontSize: "10px",
                },
              ]}
            >
              {props.visit?.facility_name}
            </Text>
            <Text
              style={[
                styles.text,
                {
                  // display: "block",
                  textAlign: "right",
                  fontStyle: "italic",
                  fontSize: "10px",
                },
              ]}
            >
              {props.visit?.facility_code}
            </Text>
            <Text
              style={[
                styles.text,
                {
                  // display: "block",
                  textAlign: "right",
                  fontStyle: "italic",
                  fontSize: "10px",
                },
              ]}
            >
              {formatDateForDb(props.visit?.visit_date)}
            </Text>
          </View>
        </View>
      ) : (
        <View
          style={{display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center"}}
        >
          <Image
            fixed={props.fixed}
            src={props.user?.company?.logo || banner}
            // src={banner}
            style={{
              width: "100%",
              maxWidth: "100%",
              height: "auto",
              flex: 0.1
            }}
          />
          <view
            style={{ flex: 0.9 , display: "flex", flexDirection: "column", alignItems: `${props.detailsAlign || "center"}`, fontSize: 16}}
          >
            <Text>
              {props.user?.company?.company_name}
            </Text>
            <Text style={{ fontSize: 12 }}>
            {props.user?.company?.physical_address}
            </Text >
            {props.showTIN &&
            <Text style={{ fontSize: 12 }}>
              {props.user?.company?.tin_number && `TIN: ${props.user?.company?.tin_number}`}
            </Text>}
            <Text style={{ fontSize: 12 }}>
              {props.user?.company?.phone_number && `Phone#: ${props.user?.company?.phone_number}`}
            </Text>
            <Text style={{ fontSize: 12 }}>
              {props.user?.company?.email && `Email: ${props.user?.company?.email}`}
            </Text>
            <Text style={{ fontSize: 12 }}>
              {props.user?.company?.pobox && `P.O. Box: ${props.user?.company?.pobox}`}
            </Text>
          </view>
        </View>
      )}
      <PatientDetails
        style={{
          paddingTop: "10pt",
          paddingBottom: "2pt",
          marginTop: "10pt",
        }}
        patient={props.patient}
      />
      {title}
      {props.date ? (
        <Text
          style={[
            props.titleTextStyle,
            {
              fontSize: 12,
              fontWeight: "bold",
              // margin: "10pt 0",
              textTransform: "uppercase",
              // borderTop: "1pt solid black",
              // padding: "10pt 0",
              textAlign: "center",
            },
          ]}
        >
          {props.date}
        </Text>
      ) : null}
      {props.headerDetails ? (
        <HeaderDetails headerDetails={props.headerDetails} />
      ) : null}
      <View style={{ padding: "5pt 0", margin: "5pt 0" }} />
    </View>
  );
}
