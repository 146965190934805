import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { Table } from "../../../components/table";
import TransactionCreate from "./TransactionCreate";

import {
  DateInput,
  SelectInput,
} from "../../../components/input";

import { useGetTripsQuery} from "../../../services/trips/TripService";
import { formatDateForDb, getModulePermissions} from "../../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { useDeletePaymentTransactionMutation, useGetPaymentTransactionsQuery } from "../../../services/accounting/PaymentTransactionService";
import ReceiptPreview from "./Receipt";
import InvoicePreview from "./Invoice";
import PaymentCreate from "../../payments/PaymentCreate";
import { capitalize } from './../../../utils/helpers/helpers';
import PaymentList from "../../payments/PaymentList";
import JournalPreview from "../journals/JournalPreview";
interface TransactionListProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TransactionList = (props: TransactionListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetPaymentTransactionsQuery({
    ...filters,
    payment_processed: 0,
  });
  const [ deleteTransactionRecord ] = useDeletePaymentTransactionMutation();

  const [documentTypes] = useState([
    {label: "Cash", value: "receipt"},
    {label: "Invoice", value: "invoice"},
  ]);

  const [paymentStatus] = useState([
    {label: "Paid", value: "Paid"},
    {label: "Unpaid", value: "Unpaid"},
    {label: "Partially Paid", value: "Partially Paid"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const addPayment = (item: any) => {
    modal.current?.show(
      "Add Payment",
      (<PaymentCreate
        tripID={item?.trip_id}
        paymentTransaction={item}
        modal={modal}
      />),
    );
  }

  const deleteTransaction = (transaction: any) => {
    modal?.current?.show(
      "Delete Transaction",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this transaction"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteTransactionRecord({id: transaction?.payment_transaction_id, trip: transaction?.trip_id})
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Transaction successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  const viewPayments = (item: any) => {
    modal.current?.show(
      "Payments",
      (<PaymentList
        title="Payments"
        exportTitle="Transaction Payments"
        trip={{ trip_id: item?.trip_id }}
        paymentTransaction={item}
        useLocalFilters
        modal={modal}
      />),
      0,
      "large"
    );
  }

  const showDoc = (item: any, docType: string) => {
    modal.current?.show(
      `${docType === "receipt" ? "Receipt" : "Invoice"}`,
      (
        docType === "receipt" ?
        <ReceiptPreview
          transactionID={item?.payment_transaction_id	}
          modal={modal}
        /> :
        <InvoicePreview
          transactionID={item?.payment_transaction_id	}
          modal={modal}
        />
      ),
      0,
    );
  }

  const previewJournal = (item: any) => {
    modal.current?.show(
      "Jouranl",
      (
        <JournalPreview
          journalID={item?.journal_id	}
          modal={modal}
        />
      ),
      0,
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Transaction Type"
            block={true}
            clearable
            options={documentTypes}
            value={documentTypes.find(type => type.value === filters.document_type)}
            onChange={(value) => dispatch(setFilters({
              document_type: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Payment Status"
            block={true}
            clearable
            options={paymentStatus}
            value={paymentStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title="Transactions"
                exportTitle={props.exportTitle}
                columns={[
                  {label: "Customer", name: "customer_name"},
                  {label: "Transaction Type", name: "transaction_type", customRender: true},
                  {label: "Transaction Date", name: "created_at"},
                  {label: "Created By", name: "employee_name"},
                  {label: "Payment Status", name: "payment_status", customRender: true},
                  {label: "Action", name: "action", customRender: true, webOnly: true},
                ]}
                items={data?.payment_transactions || []}
                // permission={permission}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                customRenders={[
                  {
                    columnName: "transaction_type",
                    render: (item) => <span>{capitalize(item.document_type)}</span>
                  },
                  {
                    columnName: "payment_status",
                    render: (item) => {
                      let payment_status_class = item.payment_status === "Paid" ? "bg-success" : (item.payment_status == "Unpaid" ? "bg-danger" : "bg-warning");
                      return <span className={`badge ${payment_status_class} text-xsmall`}>{item.payment_status}</span>
                    }
                  },
                  {
                    columnName: "action",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        <span
                          className="material-icons danger-text cursor-pointer mr-2"
                          onClick={() => deleteTransaction(item)}
                          title="Delete Record"
                        >
                          delete
                        </span>
                        {item.journal_id &&
                        <button
                          className="btn small bg-success cursor-pointer"
                          onClick={() => previewJournal(item)}
                          title="Preview Journal"
                        >
                          Journal
                        </button>}
                        <button
                          className="btn small bg-primary cursor-pointer ml-1"
                          onClick={() => viewPayments(item)}
                          title="Add Payment"
                        >
                          Payments
                        </button>
                        {item.invoice_number &&
                        <button
                          className="btn small bg-primary cursor-pointer ml-1"
                          onClick={() => showDoc(item, "invoice")}
                          title="View Invoice"
                        >
                          Invoice
                        </button>}
                        {item.receipt_number &&
                        <button
                          className="btn small bg-secondary cursor-pointer ml-1"
                          onClick={() => showDoc(item, "receipt")}
                          title="Vkew Receipt"
                        >
                          Receipt
                        </button>}
                      </div>
                    )
                  },
                  {
                    columnName: "payment",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        {item.payment_status !== "Unpaid" &&
                        <button
                          className="btn small bg-success cursor-pointer mr-1"
                          onClick={() => viewPayments(item)}
                          title="Add Payment"
                        >
                          View
                        </button>}
                        {item.payment_status !== "Paid" &&
                        <button
                          className="btn small bg-primary cursor-pointer"
                          onClick={() => addPayment(item)}
                          title="Add Payment"
                        >
                          Add
                        </button>}
                      </div>
                    )
                  }
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TransactionList;
