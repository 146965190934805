import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  SelectInput,
  TextArea,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { getValidationRules } from './../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { useCreateAccountMutation, useGetAccountToUpdateQuery } from "../../../services/accounting/AccountService";

interface AccountCreateProps {
  accountID?: any,
  modal?: MutableRefObject<any>,
}

const AccountCreate = (props: AccountCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 20}).map(() =>  React.createRef()),
    []
  );

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetAccountToUpdateQuery(props.accountID || -1);
  const [ createAccount ] = useCreateAccountMutation();

  const [accountTypes, setAccountTypes] = useState<any[]>([]);
  const [accountCategories, setAccountCategories] = useState<any[]>([]);
  const [accounts, setAccounts] = useState<any[]>([]);
  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const [accountCategoryTypes, setAccountCategoryTypes] = useState<any[]>([]);
  const [accountTypeAccounts, setAccountTypeAccounts] = useState<any[]>([]);

  const [accountLevels, setAccounttLevels] = useState<any[]>([
    {label: "Parent Account", value: "parent"},
    {label: "Child Account", value: "child"},
  ]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Account Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
          FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createAccount({...FD, id: props.accountID})
        .unwrap()
        .then((response: any) => {
          if (props.accountID) {
            dispatch(setAlert({type: "success", message: "Account Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Account has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.account,
      account_type: data.account?.account_type_id,
      account_category: data.account?.account_category_id,
      parent_account: data.account?.parent_account_id,
    });
    data?.account_types && setAccountTypes(data.account_types.map((account_type: any) => ({label: account_type.account_type_name, value: account_type.account_type_id, ...account_type})));
    data?.account_categories && setAccountCategories(data.account_categories.map((account_category: any) => ({label: account_category.account_category_name, value: account_category.account_category_id, ...account_category})));
    data?.accounts && setAccounts(data.accounts.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
    data?.currency_list && setCurrencyList(data.currency_list.map((currency: any) => ({label: `${currency.currency_code} - ${currency.currency_name}`, value: currency.currency_code, ...currency})));
  }

  useEffect(() => {
    formData.account_category ?
    setAccountCategoryTypes(accountTypes.filter((type: any) => type.account_category_id == formData.account_category)) :
    setAccountCategoryTypes(accountTypes);
  }, [formData.account_category, accountTypes])

  useEffect(() => {
    formData.account_type &&
    setAccountTypeAccounts(accounts.filter((account: any) => account.account_type_id == formData.account_type && account.account_level == "parent"))
  }, [formData.account_type, accounts])

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Account Category"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountCategories}
                    value={accountCategories.filter(accountCategory => accountCategory.value === formData.account_category)}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_category: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[1]}
                    label="Account Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountCategoryTypes}
                    value={accountCategoryTypes.filter(accountType => accountType.value === formData.account_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                     ref={inputs[2]}
                    label="Account Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.account_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Account Level"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountLevels}
                    value={accountLevels.filter(accountLevel => accountLevel.value === formData.account_level)}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_level: value?.value
                    })}
                  />
                </div>
                {formData.account_level === "child" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[4]}
                    label="Parent Account"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountTypeAccounts}
                    value={accountTypeAccounts.filter(account => account.value === formData.parent_account)}
                    onChange={(value) => setFormData({
                      ...formData,
                      parent_account: value?.value
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account Code"
                    block={true}
                    value={formData.account_code || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_code: value
                    })}
                  />
                </div>
                {formData.account_type == 2 && //bank
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account Number"
                    block={true}
                    value={formData.account_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      account_number: value
                    })}
                  />
                </div>}
                {(formData.account_type == 2 || formData.account_type == 10) && //bank or credit card
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Currency"
                    block={true}
                    options={currencyList}
                    value={currencyList.filter(currency => currency.value === formData.currency_code)}
                    onChange={(value) => setFormData({
                      ...formData,
                      currency_code: value?.value
                    })}
                  />
                </div>}
                {formData.account_type == 2 && //bank
                <div className="col-12 col-md-4">
                  <TextField
                    label="Bank Name"
                    block={true}
                    value={formData.bank_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_name: value
                    })}
                  />
                </div>}
                {(formData.account_type == 2 || formData.account_type == 10) && //bank or credit card
                <div className="col-12 col-md-4">
                  <TextField
                    label="Routing Number"
                    block={true}
                    value={formData.routing_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      routing_number: value
                    })}
                  />
                </div>}
                {(formData.account_type == 2 || formData.account_type == 10) && //bank or credit card
                <div className="col-12 col-md-4">
                  <TextField
                    label="Branch"
                    block={true}
                    value={formData.branch || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      branch: value
                    })}
                  />
                </div>}
                {/* <div className="col-12 col-md-4">
                  <TextField
                    label="Opening Balance"
                    block={true}
                    value={numberFormat(formData.opening_balance) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      opening_balance: stripCommas(value)
                    })}
                  />
                </div> */}
                <div className="col-12 col-md-4">
                  <TextArea
                    label="Description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.accountID ? "Save" : "Add Account"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default AccountCreate;
