import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../../components/modal";
import { useGetVendorQuery } from "../../../services/vendors/VendorService";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from '../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import VendorCreate from "./VendorCreate";
import TripList from "../../trips/TripList";
import RouteList from "../../routes/RouteList";
import CargoList from "../../cargos/CargoList";
import { ExpenseItems } from "../expense-items";
import { ExpenseTransactionList } from "../expense-transactions";

interface VendorDetailsProps {
  vendor?: any,
}

const VendorDetails = (props: VendorDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { vendorID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetVendorQuery(vendorID);

  const permission = useMemo(
    () => getModulePermissions("vendors", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  let editButton = (
    <button
      // className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
      className={`btn bg-secondary mt-3`}
      onClick={() =>
        // permission?.update ?
        editVendor(data?.vendor)
        // :
        // onNoAccess("edit", "vendor")
      }
    >
      Edit Vendor
    </button>
  );

  const editVendor = (vendor: any) => {
    modal.current?.show(
      "Edit Vendor Info",
      (<VendorCreate
        vendorID={vendor.vendor_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let vendor = data?.vendor;
    let vendor_status = vendor?.is_active == 1 ? "Active" : "Inactive";
    let vendor_status_class = vendor?.is_active == 1 ? "bg-success" : "bg-danger";

    setSummary({
      title: vendor?.first_name,
      summary: [
        {value: vendor?.phone_number},
        {value: vendor_status, className: `badge text-xsmall ${vendor_status_class}`},
      ],
      details: [
        {label: "Expenses", value: numberFormat(vendor?.total_expense_transactions || 0)},
        {label: "Expense Amount", value: numberFormat(vendor?.expense_amount || 0)},
        {label: "Amount Paid", value: numberFormat(vendor?.total_expense_payment || 0)},
        {label: "Unpaid Amount", value: numberFormat(vendor?.expense_amount_due || 0)},
        {label: "Items", value: numberFormat(vendor?.total_expense_items || 0)},
      ]
    });

    setBasicInfo([
      {
        label: "Name",
        value: vendor?.first_name,
      },
      {
        label: "Phone No",
        value: vendor?.phone_number,
      },
      {
        label: "Phone No. 2",
        value: vendor?.phone_number_2 || '---',
      },
      {
        label: "Email",
        value: vendor?.email || '---',
      },
      {
        label: "Address",
        value: vendor?.physical_address,
      },
      {
        label: "Status",
        value: vendor_status,
        className: `badge text-xsmal ${vendor_status_class}`
      },
    ]);
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Vendor Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Vendor"
            // permission={permission}
            details={basicInfo}
            detailsEditAction={editButton }
            summary={summary}
            tabs={[
              <ExpenseTransactionList
                key={2}
                title="Expenses"
                // className={!hasPermission("trips", user.permissions) && "disabled"}
                // onClick={!hasPermission("trips", user.permissions) ? () => onNoAccess("access", "trips") : undefined}
                exportTitle={`Vendor(${data?.vendor?.first_name}) Expenses`}
                vendor={data?.vendor}
                useLocalFilters
              />,
              <ExpenseItems
                key={3}
                title="Items"
                // className={!hasPermission("cargos", user.permissions) && "disabled"}
                // onClick={!hasPermission("cargos", user.permissions) ? () => onNoAccess("access", "cargos") : undefined}
                exportTitle={`Vendor(${data?.vendor?.first_name}) Items`}
                vendor={data?.vendor}
                useLocalFilters
              />,
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default VendorDetails;
