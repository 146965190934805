import React, { useState, forwardRef, useImperativeHandle, useEffect, ReactNode, useRef } from "react";
import ReactSelect, { components } from "react-select";

import { primaryColor } from "../../utils/constants/constants";

const styles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: state.isFocused ? "#fff" : "#000",
    backgroundColor: state.isFocused ? primaryColor : "#fff",
    cursor: "pointer",
  }),
  control: (provided: any, state: any) => ({
    width: "100%",
    height: "32px",
    padding: "0",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "transparent",
    border: "none",
    cursor: state.isDisabled ? "not-allowed" : "pointer",
    opacity: state.isDisabled ? 0.7 : 1,
  }),
  valueContainer: (provided: any, state: any) => ({
    ...provided,
    padding: 0,
    height: "32px",
  }),
  singleValue: (provided: any, state: any) => {
    return {
      ...provided,
      opacity: state.isDisabled ? 0.85 : 1,
      transition: "opacity 300ms",
      margin: 0,
    };
  },
  indicatorsContainer: (provided: any, state: any) => ({
    ...provided,
    height: "32px",
  }),
  clearIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "0 4px",
    color: "#000",
  }),
  dropdownIndicator: (provided: any, state: any) => ({
    ...provided,
    padding: "0 4px",
    color: "#000",
  }),
  indicatorSeparator: (provided: any, state: any) => ({
    ...provided,
    display: "none",
  }),
  menu: (provided: any, state: any) => ({
    ...provided,
    left: "0",
    width: "100%",
    zIndex: 9999,
    top: "calc(100% - 4px)",
    boxShadow: "0 2px 3px #ccc, 0 -1px 2px #ccc",
    border: "none",
  }),
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <span className="material-icons">close</span>
    </components.ClearIndicator>
  );
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <span className="material-icons">arrow_drop_down</span>
    </components.DropdownIndicator>
  );
};

interface SelectInputProps {
    disabled?: boolean,
    value?: {},
    onChange?: (value?: any, input?: object) => void,
    readOnly?: boolean,
    label?: string,
    placeholder?: string,
    options?: any[],
    hint?: string,
    hintClassName?: string,
    isMulti?: boolean,
    onInputChange?: (newValue: any) => void,
    requiredDecorator?: boolean,
    renderStartIcon?: () => ReactNode,
    renderEndIcon?: () => ReactNode,
    block?: boolean,
    direction?: string,
    clearable?: boolean,
    rules?: any[],
    className?: string,
    styles?: object,
};

const SelectInput = forwardRef((props: SelectInputProps, ref) => {
  var input: any = useRef();

  const [isFocused, setIsFocused] = useState(false);
  const [val, setVal] = useState(props.value);
  const [error, setError] = useState<string>();
  const [options, setOptions] = useState(props.options);
  const [hint, setHint] = useState<string>();

  const _getContainerClassName = () => {
    let className = "input-group";

    if (isFocused) {
      className += " is-focused";
    }

    if (props.block) {
      className += " block";
    }

    if (props.direction === "horizontal") {
      className += " horizontal";
    }

    if (error) {
      className += " has-error";
    }

    if (props.className) {
      className += ` ${props.className}`;
    }

    return className;
  };

  const validate = (value: any) => {
    let rules = props.rules || [],
      rulesLength = rules.length,
      i = 0;
    for (; i < rulesLength; i++) {
      // window.alert(value + ": " + val)
      let validate = rules[i](value !== undefined ? value : val);
      if (validate !== true) {
        setError(validate);
        return false;
      } else {
        setError(undefined);
      }
    }

    return true;
  };

  const clearValidation = (value: any) => {
    setError(undefined);
  }

  const _onChange = (value: any, toValidate = true) => {
    if (props.onChange) {
      props.onChange(value);
    };

    setVal(value);
    toValidate && validate(value);
  };

  const getInput = () => {
    return input;
  };

  const setValue = (value: any, validate = false) => {
    setVal(value)
    // input.setState({ value })
    _onChange(value, validate);
  };
  
  useEffect(() => {
    setVal(props.value);
  }, [props.value]);

  useEffect(() => {
    setOptions(props.options)
  }, [props.options]);

  useEffect(() => {
    setHint(props.hint)
  }, [props.hint]);

  useImperativeHandle(ref, () => {
    return {
      setValue: setValue,
      validate: validate,
      clearValidation: clearValidation,
      getInput: getInput,
    };
  });

  return (
    <div className={_getContainerClassName()}>
      {props.label ? (
        <div className="label px-1">
          {props.label}
          {props.requiredDecorator ? (
            <span className="red-text"> *</span>
          ) : null}
        </div>
      ) : null}
      <div className="flex-grow-1">
        <div className="d-flex flex-row align-center input">
          {props.renderStartIcon ? props.renderStartIcon() : null}
          <ReactSelect
            ref={(ref) => (input = ref)}
            components={{ ClearIndicator, DropdownIndicator }}
            isClearable={props.clearable}
            isDisabled={props.disabled}
            styles={props.styles || styles}
            className="select__wrapper flex-grow-1 input-field"
            classNamePrefix="select"
            options={options}
            placeholder={props.placeholder}
            isMulti={props.isMulti}
            value={val}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            onChange={(value) => _onChange(value)}
            onInputChange={(inputValue) =>
              props.onInputChange && props.onInputChange(inputValue)
            }
          />
          {props.renderEndIcon ? props.renderEndIcon() : null}
        </div>
        {error ? (
          <div className="error px-1 red-text text-small">
            {error}
          </div>
        ) : null}
        {hint ? (
          <div
            className={
              "hint px-1 text-small " +
              (props.hintClassName || "hint-text")
            }
          >
            {hint}
          </div>
        ) : null}
      </div>
    </div>
  );
});

export default SelectInput;
export {styles};