import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../components/input";

import { formatDateForDb, getDatesOptions, getModulePermissions, numberFormat} from "../../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { capitalize } from './../../../utils/helpers/helpers';
import ExpenseList from "../../expenses/ExpenseList";
import { useGetProfitAndLossQuery } from "../../../services/accounting/AccountingReportService";
import moment from "moment";
import { Modal } from "../../../components/modal";
interface ProfitAndLossProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  vendor?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ProfitAndLoss = (props: ProfitAndLossProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetProfitAndLossQuery({
    ...filters,
  });

  const [reportData, setReportData] = useState<any[]>([]);

  const [accountingMethods] = useState([
    {label: "Accrual", value: "accrual"},
    {label: "Cash", value: "cash"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const getTitleObject = (title: string, level: string = "") => {
    return {
      account_name: capitalize(title),
      isTitle: true,
      [`isSection${capitalize(level)}Title`]: true,
    }
  }

  const getTotalObject = (total: any, label: string, level: string = "") => {
    return {
      account_name: capitalize(label),
      account_code: "",
      account_balance: total,
      [`isSection${capitalize(level)}Total`]: true,
    }
  }

  const getTypeAccounts = (types: any[]) => {
    let accounts = data?.accounts?.filter((account: any) => types.includes(account.account_type_id)) || [];

    return accounts;
  }

  const getTotal = (types: any[]) => {
    let total = (data?.accounts?.filter((account: any) => types.includes(account.account_type_id)) || [])
    .reduce((acc: number, item: any) => {
      return acc + parseFloat(item.account_balance);
    }, 0);

    if(types.includes("opening_balance")){
      total += parseFloat(data?.opening_balance);
    }

    return total != 0 ? total.toFixed(2) : "0.00";
  }

  const getProfit = (incomeTypes: any[], expenseTypes: any[]) => {
    let totalIncome = (data?.accounts?.filter((account: any) => incomeTypes.includes(account.account_type_id)) || [])
    .reduce((acc: number, item: any) => {
      return acc + parseFloat(item.account_balance);
    }, 0);

    let totalExpense = (data?.accounts?.filter((account: any) => expenseTypes.includes(account.account_type_id)) || [])
    .reduce((acc: number, item: any) => {
      return acc + parseFloat(item.account_balance);
    }, 0);

    let profit = totalIncome - totalExpense;

    return profit != 0 ? profit.toFixed(2) : "0.00";
  }

  const populateData = () => {
    setReportData([
      getTitleObject("Operating Income", "Minor"),
      ...getTypeAccounts(["18"]),
      getTotalObject(getTotal(["18"]), "Total Operating Income", "Sub"),
      getTitleObject("Operating Expense", "Minor"),
      ...getTypeAccounts(["20"]),
      getTotalObject(getTotal(["20"]), "Total Operating Expense", "Sub"),

      getTotalObject(getProfit(["18"], ["20"]), "Operating Profit", "Pre"),

      getTitleObject("Non Operating Income", "Minor"),
      ...getTypeAccounts(["19"]),
      getTotalObject(getTotal(["19"]), "Total Non Operating Income", "Sub"),
      getTitleObject("Non Operating Expense", "Minor"),
      ...getTypeAccounts(["21"]),
      getTotalObject(getTotal(["21"]), "Total Non Operating Expense", "Sub"),

      getTotalObject(getProfit(["18","19"], ["20","21"]), "Net Profit/Loss"),
    ]);
  }

  useEffect(() => {
    populateData();
  }, [data])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  const columns = [
    {
      label: "Account",
      name: "account_name",
      customRender: true,
      valueGetter: (item: any) =>  item.parent_account_name ?
      `${capitalize(item.parent_account_name)} - (${capitalize(item.account_name)})` :
      `${capitalize(item.account_name)}${item.child_accounts_count > 0 ? ` - (Parent)` : ""}`,
      colSpan: (item: any, index: number, column: any) => item.isTitle && 3,
      className: (item: any, index: number, column: any) => item.isSectionTitle ? "bg-primary text-large text-center text-uppercase" : (item.isSectionSubTitle ? "bg-d text-medium" : (item.isSectionMinorTitle ? "bg-secondary text-center" : "")),
      flex: (item: any, index: number, column: any) => item.isSectionTitle ? 3 : 1,
    },
    // {label: "Account Code", name: "account_code", dataClassName: "text-italic secondary-text"},
    {label: "Total", name: "account_balance", money: true, customRender: true},
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
      <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.monthly_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              monthly_report_from_date: value?.name !== "custom" ? value?.from_date : filters.monthly_report_from_date,
              monthly_report_to_date: value?.name !== "custom" ? value?.to_date : filters.monthly_report_to_date,
              monthly_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Start Date"
            block={true}
            value={new Date(filters.monthly_report_from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom End Date"
            block={true}
            value={new Date(filters.monthly_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Accounting Method"
            block={true}
            options={accountingMethods}
            value={accountingMethods.find(method => method.value === (filters.accounting_method || "accrual"))}
            onChange={(value) => dispatch(setFilters({
              accounting_method: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>Profit and Loss ({capitalize(filters.accounting_method)} Basis) - <span className="text-small">From {moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  {moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `Profit and Loss (${capitalize(filters.accounting_method)} Basis) - From ${moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  ${moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}`}
                hideIndex
                noSearch
                // exportFormats={["pdf"]}
                columns={columns}
                items={reportData || []}
                customRenders={[
                  {
                    columnName: "account_name",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-left" : "") +  (item.isSectionPreTotal ? "text-bold bg-d text-left" : "") + (item.isSectionTotal ? "text-bold bg-c text-large" : "") + (item.labelClassName || ""),
                    render: (item) => item.parent_account_name ?
                    <span>{capitalize(item.parent_account_name)} - (<span className="text-bold">{capitalize(item.account_name)}</span>)</span> :
                    <span>
                      <span>{capitalize(item.account_name)}</span>
                      <span>
                        {item.child_accounts_count > 0 && <span> - (<span className="text-bold">Parent</span>)</span>}
                      </span>
                    </span>
                  },
                  {
                    columnName: "account_balance",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") +  (item.isSectionPreTotal ? "text-bold bg-d" : "") + (item.isSectionTotal ? "text-bold bg-c text-large" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.account_balance, 2, true)}</span>
                  },
                ]}
                // totalColumns={[
                //   { name: "account_balance" },
                // ]}
                // totalColSpan={1}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ProfitAndLoss;
