import { AppService } from '../AppService';

export const AccountService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getAccounts: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/accounting/accounts",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Accounts', 'GetAccounts'],
    }),
    getAccount: builder.query({
        query: (id) => ({
            url: `/accounting/accounts/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Accounts', 'GetAccount'],
    }),
    getAccountToUpdate: builder.query({
        query: (id) => ({
            url: `/accounting/accounts/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Accounts', 'GetAccountToUpdate'],
    }),
    createAccount: builder.mutation({
        query: (data = {}) => ({
            url: `/accounting/accounts/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Accounts'],
    }),
    deleteAccount: builder.mutation({
        query: (id) => ({
            url: `/accounting/accounts/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetAccounts'],
    }),
  }),
});

export const {
    useGetAccountsQuery,
    useGetAccountQuery,
    useGetAccountToUpdateQuery,
    useCreateAccountMutation,
    useDeleteAccountMutation,
} = AccountService;
