import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../../components/modal";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../../components/layout/item-details";
import { capitalize, getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from './../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import AccountCreate from "./AccountCreate";
import { useGetAccountQuery } from "../../../services/accounting/AccountService";
import { JournalEntryList } from "../journal-entries";

interface AccountDetailsProps {
  account?: any,
}

const AccountDetails = (props: AccountDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { accountID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetAccountQuery(accountID);

  const permission = useMemo(
    () => getModulePermissions("accounts", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  const editAccount = (account: any) => {
    modal.current?.show(
      "Edit Account Info",
      (<AccountCreate
        accountID={account.account_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let account = data?.account;

    setSummary({
      title: account?.account_name,
      summary: [
        {value: account?.account_code, show: account?.account_code},
        {value: account?.account_type_name},
        {value: account?.account_category_name},
      ],
      details: [
        {label: "Debit (TZS)", value: numberFormat(account?.total_debit || 0)},
        {label: "Credit (TZS)", value: numberFormat(account?.total_credit || 0)},
        {label: "Balance (TZS)", value: numberFormat(account?.account_balance || 0)},
      ]
    });

    setBasicInfo([
      {
        label: "Account Name",
        value: account?.account_name,
      },
      {
        label: "Account Code",
        value: account?.account_code  || "---",
      },
      {
        label: "Account Type",
        value: account?.account_type_name,
      },
      {
        label: "Account Category",
        value: account?.account_category_name,
      },
      {
        label: "Account Level",
        value: capitalize(`${account?.account_level} Account`),
      },
      {
        label: "Parent Account",
        value: `${account?.parent_account_code  ? (account?.parent_account_code + " - ") : ""}${account?.parent_account_name || "---"}`,
        show: account?.account_level === "child",
      },
      {
        label: "Account Number",
        value: account?.account_number  || "---",
        show: account?.account_type_id == 2,
      },
      {
        label: "Currency",
        value: account?.currency_code || "---",
        show: account?.account_type_id == 2 || account?.account_type_id == 10,
      },
      {
        label: "Bank Name",
        value: account?.bank_name || "---",
        show: account?.account_type_id == 2,
      },
      {
        label: "Routing Number",
        value: account?.routing_number || "---",
        show: account?.account_type_id == 2 || account?.account_type_id == 10,
      },
      {
        label: "Branch",
        value: account?.branch || "---",
        show: account?.account_type_id == 2 || account?.account_type_id == 10,
      },
      // {
      //   label: "Opening Balance",
      //   value: account?.opening_balance || "---",
      // },
      {
        label: "Description",
        value: account?.description || "---",
      },
    ]);
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Account Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Account"
            // permission={permission}
            details={basicInfo}
            detailsEditAction={
              data?.account?.company_id != 0 &&
              <button
                // className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
                className={`btn bg-secondary mt-3`}
                onClick={() =>
                  // permission?.update ?
                  editAccount(data?.account)
                  // :
                  // onNoAccess("edit", "account")
                }
              >
                Edit Account
              </button>
            }
            summary={summary}
            tabs={[
              <JournalEntryList
                key={3}
                title="Transactions"
                // className={!hasPermission("expenses", user.permissions) && "disabled"}
                // onClick={!hasPermission("expenses", user.permissions) ? () => onNoAccess("access", "expenses") : undefined}
                exportTitle={`Account(${data?.account?.account_code ? (data?.account?.account_code + " - ") : ""}${data?.account?.account_name}) Transactions`}
                account={data?.account}
                useLocalFilters
              />,
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default AccountDetails;
