import { useEffect, useMemo, useRef } from "react";
import { Modal } from "../../../components/modal";
import { Table } from "../../../components/table";

import {
  DateInput,
} from "../../../components/input";

import { formatDateForDb, getModulePermissions, getNoPermissionMessage, padStart } from "../../../utils/helpers/helpers";
import { numberFormat } from './../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { setFilters as setFilter } from "../../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { useGetJournalEntriesQuery } from "../../../services/accounting/JournalEntryService";

interface JournalEntryListProps {
  exportTitle?: string,
  account?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const JournalEntryList = (props: JournalEntryListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetJournalEntriesQuery({
    ...filters,
    account: filters.account || props.account?.account_id, 
  });

  const permission = useMemo(
    () => getModulePermissions("journal_entries", user?.permissions),
    [user]
  );

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1 justify-center">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Transactions"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Date", name: "transaction_date"},
                {label: "Description", name: "description"},
                {label: "Transaction #", name: "transaction_number", customRender: true},
                {label: "Reference #", name: "reference"},
                {label: "Debit (TZS)", name: "debit", customRender: true},
                {label: "Credit (TZS)", name: "credit", customRender: true},
                {label: "Amount (TZS)", name: "amount", customRender: true},
              ]}
              items={data?.journal_entries || []}
              // permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              customRenders={[
                {
                  columnName: "transaction_number",
                  render: (item, index) => <span>{item.transaction_number && padStart(item.transaction_number, 4, "0")}</span>
                },
                {
                  columnName: "debit",
                  render: (item, index) => <span>{item.debit && numberFormat(item.debit, 2)}</span>
                },
                {
                  columnName: "credit",
                  render: (item, index) => <span>{item.credit && numberFormat(item.credit, 2)}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount, 2)}</span>
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default JournalEntryList;
