import { AppService } from '../AppService';

export const AccountingReportService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getBalanceSheet: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/accounting/accounting-reports/balance-sheet",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'AccountingReports', 'GetBalanceSheet'],
    }),
    getTrialBalance: builder.query({
      query: (params = {page: 1, limit: 10}) => ({
          url: "/accounting/accounting-reports/trial-balance",
          method: "GET",
          params: params,
      }),
      providesTags: ['App', 'AccountingReports', 'GetTrialBalance'],
    }),
    getGeneralLedger: builder.query({
      query: (params = {page: 1, limit: 10}) => ({
          url: "/accounting/accounting-reports/general-ledger",
          method: "GET",
          params: params,
      }),
      providesTags: ['App', 'AccountingReports', 'GetGeneralLedger'],
    }),
    getCashFlow: builder.query({
      query: (params = {page: 1, limit: 10}) => ({
          url: "/accounting/accounting-reports/cash-flow",
          method: "GET",
          params: params,
      }),
      providesTags: ['App', 'AccountingReports', 'GetCashFlow'],
    }),
    getProfitAndLoss: builder.query({
      query: (params = {page: 1, limit: 10}) => ({
          url: "/accounting/accounting-reports/profit-and-loss",
          method: "GET",
          params: params,
      }),
      providesTags: ['App', 'AccountingReports', 'GetProfitAndLoss'],
    }),
    getMovementOfEquity: builder.query({
      query: (params = {page: 1, limit: 10}) => ({
          url: "/accounting/accounting-reports/movement-of-equity",
          method: "GET",
          params: params,
      }),
      providesTags: ['App', 'AccountingReports', 'GetMovementOfEquity'],
    }),
  }),
});

export const {
  useGetBalanceSheetQuery,
  useGetTrialBalanceQuery,
  useGetGeneralLedgerQuery,
  useGetCashFlowQuery,
  useGetProfitAndLossQuery,
  useGetMovementOfEquityQuery,
} = AccountingReportService;
