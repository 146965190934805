import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../components/modal";
import { Table } from "../../components/table";
import TransactionCreate from "./payment-transactions/TransactionCreate";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { useGetTripsQuery} from "../../services/trips/TripService";
import { formatDateForDb, getModulePermissions} from "../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../states/FiltersState";
import { clearAlert, setAlert } from "../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState";
interface UnprocessedPaymentTripListProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  customer?: any,
  cargo?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const UnprocessedPaymentTripList = (props: UnprocessedPaymentTripListProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTripsQuery({
    ...filters,
    payment_processed: 0,
  });

  const [tripTypes] = useState([
    {label: "Singletrip", value: "1"},
    {label: "Roundtrip", value: "0"},
  ]);

  const [tripStatus] = useState([
    {label: "Completed", value: "0"},
    {label: "Ongoing", value: "1"},
    {label: "Yet to start", value: "2"},
    {label: "Yet to Assign", value: "3"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const showPaymentProcessing = (trip: any) => {
    modal.current?.show(
      `Payment Processing`,
      (<TransactionCreate
        trip={trip}
        modal={modal}
      />),
      0,
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Type"
            block={true}
            clearable
            options={tripTypes}
            value={tripTypes.find(type => type.value === filters.type)}
            onChange={(value) => dispatch(setFilters({
              type: value?.value,
            }))}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Status"
            block={true}
            clearable
            options={tripStatus}
            value={tripStatus.find(status => status.value === filters.status)}
            onChange={(value) => dispatch(setFilters({
              status: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title="Unprocessed Payment Trips"
                exportTitle={props.exportTitle}
                columns={[
                  {label: "Entry Date", name: "created_at"},
                  {label: "Customer", name: "customer_name"},
                  {label: "Route", name: "route"},
                  {label: "Departure Date", name: "start_date"},
                  {
                    label: "Type",
                    name: "trip_type",
                    customRender: true,
                    valueGetter: (item) =>  item.trip_type === "1" ? "Singletrip" : "Roundtrip",
                  },
                  {
                    label: "Status",
                    name: "status",
                    customRender: true,
                    valueGetter: (item) =>  item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined))),
                  },
                  {label: "Action", name: "action", customRender: true, webOnly: true},
                ]}
                items={data?.trips || []}
                // permission={permission}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                pagination={{
                  ...(data?.pagination || {}),
                  onPageChange: (page) => dispatch(setFilters({page: page})),
                  onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                }}
                params={filters}
                customRenders={[
                  {
                    columnName: "trip_type",
                    render: (item) => <span>{item.trip_type === "1" ? "Singletrip" : "Roundtrip"}</span>
                  },
                  {
                    columnName: "status",
                    render: (item) => {
                      let trip_status = item.status == 0 ? "Completed" : (item.status == 1 ? "Ongoing" : (item.status == 2 ? "Yet to start" : (item.status == 3 ? "Yet to Assign" : undefined)));
                      let trip_status_class = item.status == 0 ? "bg-success" : (item.status == 1 ? "bg-secondary" : (item.status == 2 ? "bg-warning" : (item.status == 3 ? "bg-danger" : "light")));
                      return <span className={`badge ${trip_status_class} text-xsmall`}>{trip_status}</span>
                    }
                  },
                  {
                    columnName: "action",
                    render: (item) => (
                      <div className="d-flex flex-row align-center">
                        <button
                          className="btn small bg-primary cursor-pointer ml-2"
                          onClick={() => showPaymentProcessing(item)}
                          title="Process Payment"
                        >
                          Process Payment
                        </button>
                      </div>
                    )
                  }
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default UnprocessedPaymentTripList;
