import { AppService } from '../../AppService';

export const ChargeService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getCharges: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/charges",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Charges', 'GetCharges'],
    }),
    getCharge: builder.query({
        query: (id) => ({
            url: `/charges/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Charges', 'GetCharge'],
    }),
    getChargeToUpdate: builder.query({
        query: (id) => ({
            url: `/charges/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Charges', 'GetChargeToUpdate'],
    }),
    createCharge: builder.mutation({
        query: (data = {}) => ({
            url: `/charges/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Charges'],
    }),
    deleteCharge: builder.mutation({
        query: (data = {}) => ({
            url: `/charges/${data.id}//${data.account}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetCharges'],
    }),
  }),
});

export const {
    useGetChargesQuery,
    useGetChargeQuery,
    useGetChargeToUpdateQuery,
    useCreateChargeMutation,
    useDeleteChargeMutation,
} = ChargeService;
