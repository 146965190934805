import React, { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  DateInput,
  FileInput,
  SelectInput,
  TextArea,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { capitalize, formatDateForDb, getValidDateOrNull, stripCommas } from "../../../utils/helpers/helpers";
import { getValidationRules } from '../../../utils/helpers/helpers';
import { numberFormat } from '../../../utils/helpers/helpers';
import { clearAlert, setAlert } from "../../../states/AlertState";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/table";
import { ExpenseBill } from "./ExpenseBill";
import { pdf } from "@react-pdf/renderer";
import { Receipt } from "./Receipt";
import { useGetVendorsQuery } from "../../../services/vendors/VendorService";
import { useGetExpenseCategoryItemsQuery } from "../../../services/settings/expenses/expense-category-items/ExpenseCategoryItemService";
import { useGetExpenseCategoriesQuery } from "../../../services/settings/expenses/expense-categories/ExpenseCategoryService";
import { useCreateExpenseTransactionMutation, useGetExpenseTransactionToUpdateQuery } from "../../../services/accounting/ExpenseTransactionService";
import { useGetChargesQuery } from "../../../services/settings/general/ChargeService";
import { useGetAccountsQuery } from "../../../services/accounting/AccountService";

interface TransactionCreateProps {
  expenseTransaction?: any,
  trip?: any,
  tripID?: any,
  vehicle?: any,
  employee?: any,
  expenseCategoryID?: any,
  vendor?: any,
  modal?: MutableRefObject<any>,
}

const TransactionCreate = (props: TransactionCreateProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 10}).map(() =>  React.createRef()),
    []
  );
  const entryInputs: any = useMemo(
    () => Array.from({length: 100}).map(() =>  React.createRef()),
    []
  );
  const taxInputs: any = useMemo(
    () => Array.from({length: 100}).map(() =>  React.createRef()),
    []
  );
  const otherChargeInputs: any = useMemo(
    () => Array.from({length: 100}).map(() =>  React.createRef()),
    []
  );

  const newExpenseEntry = {expense_transaction_item_id: undefined, expense_category_id: undefined, account_id: undefined, expense_item_id: undefined, rate: "0.00", quantity: "1.00", amount: "0.00"};
  const newChargeEntry = {charge_id: undefined, account_id: undefined, charge_name: undefined, charge_amount: undefined, charge_type: undefined, charge_category: undefined};

  const [formData, setFormData] = useState<any>({});

  const { data, isLoading, isSuccess, isError, error } = useGetExpenseTransactionToUpdateQuery(props.expenseTransaction?.expense_transaction_id || -1);
  const [ createExpenseTransaction ] = useCreateExpenseTransactionMutation();

  const { data: vendors } = useGetVendorsQuery({});
  const { data: expenseItems } = useGetExpenseCategoryItemsQuery({});
  const { data: expenseCategories } = useGetExpenseCategoriesQuery({});
  const { data: charges } = useGetChargesQuery({account_category: "expense"});
  const { data: accounts } = useGetAccountsQuery({});

  const [expenseAmountReload, setexpenseAmountReload] = useState<any>(0);
  const [transactionSummary, setTransactionSummary] = useState<any>([]);
  const [vendorList, setVendorList] = useState<any>([]);
  const [accountList, setAccountList] = useState<any>([]);
  const [expenseCategoryList, setExpenseCategoryList] = useState<any>([]);
  const [taxList, setTaxList] = useState<any>([]);
  const [otherChargeList, setOtherChargeList] = useState<any>([]);
  const [expenseCatgoryItems, setExpenseCatgoryItems] = useState<any>({});
  const [expenseEntries, setExpenseEntries] = useState<any[]>(Array.from({length: 1}).map(() => newExpenseEntry));
  const [taxEntries, setTaxEntries] = useState<any[]>([]);
  const [otherChargeEntries, setOtherChargeEntries] = useState<any[]>([]);


  const [transactionTypes] = useState([
    {label: "Cash", value: "cash", document_type: "receipt"},
    {label: "Bill", value: "credit", document_type: "expense_bill"},
  ]);

  const [discountTypes] = useState<any[]>([
    {label: "Percent", value: "percent"},
    {label: "Fixed", value: "fixed"},
  ]);

  const save = () => {
    let formValidate = form?.current?.validate();
    let tableInputsValidate = validateTableInputs();
    if(!formValidate || !tableInputsValidate) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
        promptMessage={`Are you sure want to save this Expense Info`}
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Processing..."}));
          modal?.current?.hide();
          let formDt = {
            ...formData,
            expense_items: JSON.stringify(
              expenseEntries.filter((entry) => !!entry.expense_category_id)
            ),
            charges: JSON.stringify(
              formData.charges
            )
          }
          let FD: any = {};
          Object.keys(formDt).forEach((key) => {
            if(formDt[key]){
              FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
            }
          });
          let FData = new FormData();
          Object.keys(FD).forEach((key) => {
            FData.append(key, FD[key]);
          });
          createExpenseTransaction({data: FData, id: props.expenseTransaction?.expense_transaction_id})
          .unwrap()
          .then((response: any) => {
            if (props.expenseTransaction?.expense_transaction_id) {
              dispatch(setAlert({type: "success", message: "Expense Info has successfully saved", unreplaceable: true}));
            }
            else {
              dispatch(setAlert({type: "success", message: "Expense has successfully added", unreplaceable: true}));
            }
            setTimeout(() => {
              props.modal?.current?.hide();
            }, 1500);
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message, unreplaceable: true}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  }

  const validateTableInputs = () => {
    let noError = true;
    formData.transaction_type !== "cash" && expenseEntries.forEach((entry, index) => {
      for(let i = 0; i < 4; i++){
        if(!entryInputs[i + index*4].current?.validate())
          noError = false;
      }
    })
    taxEntries.forEach((entry, index) => {
      for(let i = 0; i < 1; i++){
        if(!taxInputs[i + index*1].current?.validate())
          noError = false;
      }
    })
    otherChargeEntries.forEach((entry, index) => {
      for(let i = 0; i < 1; i++){
        if(!otherChargeInputs[i + index*1].current?.validate())
          noError = false;
      }
    })

    return noError;
  }

  const getSubTotal = (data: any, totalColumns: any = []) => {
    let totalObject: any = {};
    totalColumns?.forEach((column: any) => {
      totalObject[column] = data?.reduce((acc: number, curr: any) => {
        return  acc + parseFloat(stripCommas(curr[column]) || 0);
      }, 0);
    });
    return totalObject;
  }

  const getTotalCharges = (data: any) => {
      return data?.reduce((acc: number, curr: any) => {
        let charge_amount = curr.charge_amount || 0;
        return  acc + parseFloat(`${curr.charge_type === "percent" ? (formData.sub_total || 0) * (charge_amount / 100) : charge_amount}`);
      }, 0);
  }

  const addEntryRow = () => {
    setExpenseEntries([
      ...expenseEntries,
      newExpenseEntry,
    ])
  }

  const addChargeRow = (charge_category: any) => {
    let charges = charge_category === "tax" ? taxEntries : otherChargeEntries;
    let setCharges = charge_category === "tax" ? setTaxEntries : setOtherChargeEntries;
    setCharges([
      ...charges,
      {...newChargeEntry, charge_category: charge_category},
    ])
  }

  const removeExpenseEntry = (index: any) => {
    setExpenseEntries(expenseEntries.filter((entry, i) => i !== index))
  }

  const removeChargeEntry = (charge_category: any, index: any) => {
    let charges = charge_category === "tax" ? taxEntries : otherChargeEntries;
    let setCharges = charge_category === "tax" ? setTaxEntries : setOtherChargeEntries;
    setCharges(charges.filter((entry, i) => i !== index))
  }

  const generateDoc = useCallback(async (data: any, docType: string) => {
    const blob = await pdf(
     docType === "receipt" ? <Receipt data={data} user={user} /> : <ExpenseBill data={data} user={user} />
    ).toBlob();

    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }, []);

  const loadCategoryItems = (category: any) => {
    let items = expenseItems?.expense_category_items?.length &&
      (expenseItems.expense_category_items?.filter((expense_item: any) => expense_item.expense_category_id == category)
        ?.map((expense_item: any) => ({label: expense_item.expense_category_item_name, value: expense_item.expense_category_item_id, ...expense_item})));

    let categoryItems = {...expenseCatgoryItems}
    categoryItems[category] = items || [];
    setExpenseCatgoryItems(categoryItems);
  }

  const loadBatchCategoryItems = (categories: any) => {
    let categoryItems = {...expenseCatgoryItems};
    categories?.forEach((category: any) => {
      let items = expenseItems?.expense_category_items?.length &&
        (expenseItems.expense_category_items?.filter((expense_item: any) => expense_item.expense_category_id == category)
          ?.map((expense_item: any) => ({label: expense_item.expense_category_item_name, value: expense_item.expense_category_item_id, ...expense_item})));
      categoryItems[category] = items || [];
    });
    setExpenseCatgoryItems(categoryItems);
  }

  useEffect(() => {
    let {
      sub_total,
      discount,
      discount_type,
      expense_items,
      charges,
      total_charges,
      total_amount,
      total_discount,
      expense_amount,
      total_expense_payment,
      expense_amount_due,
      transaction_type,
    } = formData || {};

    setTransactionSummary([
      {
        label: "Subtotal",
        value: numberFormat(sub_total || 0, 2),
         className: "text-bold"
      },
      {
        label: `Discount${discount_type === "percent" ? `(${numberFormat(discount || 0)}%)` : ""}`,
        value: `${total_discount > 0 ? "(-) " : ""}${numberFormat(total_discount || 0, 2)}`,
      },
      ...(
        charges
        ?.filter((charge: any) => charge.charge_category === "tax")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Tax`,
          value: charge.charge_type === "percent" ? numberFormat((sub_total || 0) * (charge.charge_amount / 100), 2) : numberFormat(charge.charge_amount, 2),
        })) || []
      ),
      ...(
        charges
        ?.filter((charge: any) => charge.charge_category === "other")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Charge`,
          value: charge.charge_type === "percent" ? numberFormat((sub_total || 0) * (charge.charge_amount / 100), 2) : numberFormat(charge.charge_amount, 2),
        })) || []
      ),
      {
        label: "Total",
        value: numberFormat(expense_amount || 0, 2),
        className: "text-bold"
      },
      {
        label: "Amount Paid",
        value: numberFormat(total_expense_payment || 0, 2),
        show: transaction_type !== "cash"
      },
      {
        label: `${expense_amount_due < 0 ? "Change Due" : "Amount Due"}, TZS`,
        value: numberFormat(Math.abs(expense_amount_due || 0), 2),
        show: transaction_type !== "cash",
        className: "text-bold text-large"
      },
    ]);

  }, [formData])

  const populateData = (data: any) => {
    setFormData({
      ...data.expense_transaction,
    })
    if(data?.expense_transaction?.expense_items){
      setExpenseEntries(data.expense_transaction.expense_items);
      loadBatchCategoryItems(data.expense_transaction.expense_items.map((item: any) => item.expense_category_id));
      // data.expense_transaction.expense_items.forEach((item: any) => {
      //   loadCategoryItems(item.expense_category_id);
      // })
    }
    if(data?.expense_transaction?.charges){
      ["tax", "other"].forEach(category => {
        let setChargeEntries = category === "tax" ? setTaxEntries : setOtherChargeEntries;
        setChargeEntries(data.expense_transaction.charges.filter((charge: any) => charge.charge_category === category));
      })
    }
  }

  useEffect(() => {
    vendors?.vendors?.length && setVendorList(vendors?.vendors?.map((vendor: any) => ({label: vendor.first_name, value: vendor.vendor_id, ...vendor})));
  }, [vendors])

  useEffect(() => {
    accounts?.accounts?.length && setAccountList(accounts?.accounts?.filter((account: any) => ["1", "2", "10"].includes(account.account_type_id))
      ?.map((account: any) => ({label: account.account_name, value: account.account_id, ...account})));
  }, [accounts])

  // useEffect(() => {
  //   (formData.expense_category && expenseItems?.expense_category_items?.length) &&
  //     setExpenseItemList( expenseItems.expense_category_items.filter((expense_item: any) => expense_item.expense_category_id == formData.expense_category)
  //       ?.map((expense_item: any) => ({label: expense_item.expense_category_item_name, value: expense_item.expense_category_item_id, ...expense_item})));
  // }, [formData.expense_category]);

  useEffect(() => {
    if(charges?.charges?.length){
      // loadCharges
      ["tax", "other"].forEach(category => {
        let setChargeList = category === "tax" ? setTaxList : setOtherChargeList;
        let chargeList = charges?.charges?.filter((charge: any) => charge.category === category
          // && charge.account_type === expenseCategoryList?.find((expenseCategory: any) => expenseCategory.expense_category_id == expenseEntries[0]?.expense_category_id)?.account_type
        )
        ?.map((charge: any) => ({
          label: charge.name,
          value: charge.charge_id,
          charge_name: charge.name,
          charge_amount: charge.amount,
          charge_type: charge.type,
          charge_category: charge.category,
          account_id: charge.account_id,
          account_type_id: charge.account_type_id,
          charge_id: charge.charge_id,
        }));
        setChargeList(chargeList);
      })
    }
  }, [charges, formData.transaction_type, expenseEntries, expenseCategoryList])

  useEffect(() => {
    setFormData({
      ...formData,
      sub_total: getSubTotal(expenseEntries, ["amount"]).amount
    })
  }, [expenseEntries])

  useEffect(() => {
    let discount = formData.discount || 0;
    setFormData({
      ...formData,
      total_discount: formData.discount_type === "percent" ? (formData.sub_total || 0) * (discount / 100) : discount
    })
    setexpenseAmountReload(expenseAmountReload + 1);
  }, [formData.discount, formData.discount_type, formData.sub_total])

  useEffect(() => {
    setFormData({
      ...formData,
      charges: taxEntries.concat(otherChargeEntries).filter((charge: any) => !!charge.charge_id)
    })
  }, [taxEntries, otherChargeEntries])

  useEffect(() => {
    setFormData({
      ...formData,
      expense_amount: parseFloat(formData.sub_total || 0) + parseFloat(getTotalCharges(formData.charges) || 0) - parseFloat(formData.total_discount || 0)
    })
  }, [formData.charges, formData.transaction_type, expenseAmountReload])

  useEffect(() => {
    setFormData({
      ...formData,
      expense_amount_due: (formData.expense_amount || 0) - (formData.total_expense_payment || 0)
    })
  }, [formData.expense_amount])

  useEffect(() => {
    expenseCategories?.expense_categories?.length && setExpenseCategoryList(expenseCategories.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id, ...expense_category})));
  }, [expenseCategories]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
      isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading]);

  useEffect(() => {
      isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  let attachmentUrlParts = typeof formData.supporting_doc?.split === "function" && formData.supporting_doc?.split("/");
  let attachmentName = attachmentUrlParts.length && attachmentUrlParts[attachmentUrlParts.length-1];

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Expense Type"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    disabled={props.expenseTransaction}
                    options={transactionTypes}
                    value={transactionTypes.filter(type => type.value == formData.transaction_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      transaction_type: value?.value,
                      document_type: value?.document_type
                    })}
                  />
                </div>
                {formData.transaction_type === "credit" &&
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Bill Number"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.bill_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bill_number: value
                    })}
                  />
                </div>}
                {formData.transaction_type === "credit" &&
                <div className="col-12 col-md-4">
                  <TextField
                    label="Order Number"
                    block={true}
                    value={formData.order_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      order_number: value
                    })}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[2]}
                    label="Expense Category"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={expenseCategoryList}
                    value={expenseCategoryList.filter((category: any) => category.value == expenseEntries[0]?.expense_category_id)}
                    onChange={(value) => {
                      let entry = {...expenseEntries[0]};
                      entry.expense_category_id = value?.value;
                      entry.account_id = value?.account_id;
                      entry.account_type_id = value?.account_type_id;
                      setExpenseEntries([entry]);
                      loadCategoryItems(value?.value);
                      // loadCharges(value.account_type);
                    }}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[3]}
                    label="Expense Item"
                    block={true}
                    // requiredDecorator={true}
                    // rules={[inputValidation.required]}
                    options={expenseCatgoryItems[expenseEntries[0]?.expense_category_id]}
                    value={expenseCatgoryItems[expenseEntries[0]?.expense_category_id]?.filter((item: any) => item.value == expenseEntries[0]?.expense_item_id)}
                    onChange={(value) => {
                      let entry = {...expenseEntries[0]};
                      entry.expense_item_id  = value?.value;
                      setExpenseEntries([entry]);
                    }}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                   <TextField
                    label="Quantity"
                    block={true}
                    value={expenseEntries[0]?.quantity && numberFormat(expenseEntries[0]?.quantity, 2) || ""}
                    onChange={(value) => {
                      let entry = {...expenseEntries[0]};
                      entry.quantity = stripCommas(value);
                      setExpenseEntries([entry]);
                    }}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[4]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label={`${formData.document_type === "bill" ? "Bill Date" : "Expense Date"}`}
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.expense_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        expense_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[5]}
                    label="Amount"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required,  inputValidation.nonZero]}
                    value={numberFormat(expenseEntries[0]?.amount) || ""}
                    onChange={(value) => {
                      let entry = {...expenseEntries[0]};
                      entry.amount = stripCommas(value);
                      setExpenseEntries([entry]);
                      // setFormData({
                      //   ...formData,
                      //   sub_total: stripCommas(value)
                      // })
                    }}
                    onBlur={(value) => {
                      let entry = {...expenseEntries[0]};
                      entry.amount = Math.abs(stripCommas(value));
                      setExpenseEntries([entry]);
                    }}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <SelectInput
                    label="Discount Type"
                    block={true}
                    clearable
                    options={discountTypes}
                    value={discountTypes.filter(discountType => discountType.value === formData.discount_type)}
                    onChange={(value) => setFormData({
                      ...formData,
                      discount_type: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Discount Rate/Amount"
                    block={true}
                    rules={[inputValidation.number]}
                    value={numberFormat(formData.discount, 2) || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      discount: stripCommas(value)
                    })}
                    onBlur={(value) => setFormData({
                      ...formData,
                      discount: Math.abs(stripCommas(value))
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[6]}
                    label="Vendor"
                    block={true}
                    requiredDecorator={formData.transaction_type !== "cash"}
                    rules={[...(formData.transaction_type !== "cash" ? [inputValidation.required] : [])]}
                    options={vendorList}
                    value={vendorList.filter((vendor: any) => vendor.value == formData.vendor)}
                    onChange={(value) => setFormData({
                      ...formData,
                      vendor: value?.value
                    })}
                  />
                </div>
                {formData.transaction_type === "credit" &&
                <div className="col-12 col-md-4">
                  <DateInput
                    ref={inputs[7]}
                    renderEndIcon={() => (
                      <span className="material-icons">calendar_today</span>
                    )}
                    label="Bill Due Date"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={getValidDateOrNull(formData.due_date)}
                    onChange={(value) => {
                      setFormData({
                        ...formData,
                        due_date: formatDateForDb(value),
                      })
                    }}
                  />
                </div>}
                {formData.transaction_type === "cash" &&
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[6]}
                    label="Paid Through"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={accountList}
                    value={accountList.filter((account: any) => account.value == formData.payment_account)}
                    onChange={(value) => setFormData({
                      ...formData,
                      payment_account: value?.value,
                      payment_account_category: value?.account_category_id
                    })}
                  />
                </div>}
                <div className="col-12 col-md-4">
                  <FileInput
                    label="Attachment"
                    placeholder={attachmentName || "Chose file..."}
                    block={true}
                    onChange={(value) => setFormData({
                      ...formData,
                      supporting_doc: value && value[0]
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    label="description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
              {/*Transaction Items list */}
              <div className="card mt-4">
                <div className="card-body">
                  {formData.transaction_type !== "cash" && <>
                    <div>
                      <Table
                        title="Items"
                        className="no-table-striped"
                        hideIndex
                        noExport
                        noSearch
                        columns={[
                          {label: "Expense Category", name: "expense_category", customRender: true},
                          {label: "Item", name: "item", customRender: true},
                          {label: "Rate, TZS", name: "rate", customRender: true},
                          {label: "Quantity", name: "quantity", customRender: true},
                          {label: "Amount, TZS", name: "amount", customRender: true},
                          {label: "", name: "action", customRender: true},
                        ]}
                        items={expenseEntries || []}
                        customRenders={[
                          {
                            columnName: "expense_category",
                            render: (item, index) => (
                              <SelectInput
                                ref={entryInputs[0 + index*4]}
                                block={true}
                                rules={[inputValidation.required]}
                                options={expenseCategoryList}
                                value={expenseCategoryList.filter((expense_category: any) => expense_category.value === item.expense_category_id)}
                                onChange={(value: any) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  entries[index].expense_category_id = value?.value;
                                  entries[index].account_id = value?.account_id;
                                  entries[index].account_type_id = value?.account_type_id;
                                  setExpenseEntries(entries);
                                  loadCategoryItems(value?.value);
                                }}
                              />
                            )
                          },
                          {
                            columnName: "item",
                            render: (item, index) => {
                              let options = expenseCatgoryItems[item.expense_category_id] || [];
                              return <SelectInput
                                ref={entryInputs[1 + index*4]}
                                block={true}
                                options={options}
                                value={options.filter((expense_item: any) => expense_item.value === item.expense_item_id)}
                                onChange={(value: any) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  entries[index].expense_item_id = value?.value;
                                  setExpenseEntries(entries);
                                }}
                              />
                              }
                          },
                          {
                            columnName: "rate",
                            render: (item, index) => (
                              <TextField
                                ref={entryInputs[2 + index*4]}
                                block={true}
                                rules={[inputValidation.number]}
                                value={item.rate && numberFormat(item.rate, 2) || ""}
                                onChange={(value) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  entries[index].rate = stripCommas(value);
                                  if(entries[index].quantity){
                                    entries[index].amount = Math.abs(stripCommas(value)) * entries[index].quantity;
                                  }
                                  setExpenseEntries(entries);
                                }}
                                onBlur={(value) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  if(value){
                                    entries[index].rate = Math.abs(stripCommas(value));
                                  }
                                  else{
                                    entries[index].rate = "0.00";
                                    entries[index].amount = "0.00";
                                  }
                                  setExpenseEntries(entries)
                                }}
                              />
                            )
                          },
                          {
                            columnName: "quantity",
                            render: (item, index) => (
                              <TextField
                                ref={entryInputs[3 + index*4]}
                                block={true}
                                rules={[inputValidation.number]}
                                value={item.quantity && numberFormat(item.quantity, 2) || ""}
                                onChange={(value) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  entries[index].quantity = stripCommas(value);
                                  if(entries[index].rate){
                                    entries[index].amount = Math.abs(stripCommas(value)) * entries[index].rate;
                                  }
                                  setExpenseEntries(entries);
                                }}
                                onBlur={(value) => {
                                  let entries = expenseEntries.map(entry => ({...entry}));
                                  if(value){
                                    entries[index].quantity = Math.abs(stripCommas(value));
                                  }
                                  else{
                                    entries[index].quantity = "1.00";
                                    entries[index].amount = 1 * (entries[index].rate || 0);
                                  }
                                  setExpenseEntries(entries)
                                }}
                              />
                            )
                          },
                          {
                            columnName: "amount",
                            render: (item, index) => (
                              <span>{numberFormat(item.amount, 2) || ""}</span>
                            )
                          },
                          {
                            columnName: "action",
                            render: (item, index) => (
                              <div className="d-flex align-center">
                                {index > 0 &&
                                <span
                                  className="material-icons cursor-pointer danger-text text-large"
                                  onClick={(e) => {
                                    e.stopPropagation(); 
                                    removeExpenseEntry(index)
                                  }}
                                  title="Delete Record"
                                >
                                  close
                                </span>}
                              </div>
                            )
                          }
                        ]}
                      />
                    </div>
                    <div className="d-flex justify-end mt-2">
                      <button
                        className="btn small bg-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          addEntryRow();
                        }}
                      >
                        <span className="material-icons">add</span> Add New Row
                      </button>
                    </div>
                  </>}
                  <div className="mt-3 d-flex justify-space-between">
                    <div className="col-12 col-md-5 col-sm-8">
                      <div>
                        <Table
                          title="Taxes"
                          className="no-table-striped"
                          hideIndex
                          noExport
                          noSearch
                          columns={[
                            {label: "Tax Name", name: "charge", customRender: true},
                            {label: "Rate/Amount", name: "charge_amount", customRender: true},
                            {label: "Type", name: "charge_type", customRender: true},
                            {label: "", name: "action", customRender: true},
                          ]}
                          items={taxEntries || []}
                          customRenders={[
                            {
                              columnName: "charge",
                              render: (item, index) => (
                                <SelectInput
                                  ref={taxInputs[0 + index*1]}
                                  block={true}
                                  rules={[inputValidation.required]}
                                  options={taxList}
                                  value={taxList.filter((charge: any) => charge.value === item.charge_id)}
                                  onChange={(value: any) => {
                                    let entries = taxEntries.map(entry => ({...entry}));
                                    entries[index] = value;
                                    setTaxEntries(entries);
                                  }}
                                />
                              )
                            },
                            {
                              columnName: "charge_amount",
                              render: (item, index) => (
                                <span>{numberFormat(item.charge_amount, 2) || ""}</span>
                              )
                            },
                            {
                              columnName: "charge_type",
                              render: (item, index) => (
                                <span>{capitalize(item.charge_type) || ""}</span>
                              )
                            },
                            {
                              columnName: "action",
                              render: (item, index) => (
                                <div className="d-flex align-center">
                                  <span
                                    className="material-icons cursor-pointer danger-text text-large"
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      removeChargeEntry("tax", index)
                                    }}
                                    title="Remove Row"
                                  >
                                    close
                                  </span>
                                </div>
                              )
                            }
                          ]}
                        />
                      </div>
                      <div className="d-flex justify-end mt-2">
                        <button
                          className="btn small bg-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            addChargeRow("tax");
                          }}
                        >
                          <span className="material-icons">add</span> Add New Row
                        </button>
                      </div>
                      <div className="mt-5">
                        <Table
                          title="Other Charges"
                          className="no-table-striped"
                          hideIndex
                          noExport
                          noSearch
                          columns={[
                            {label: "Charge Name", name: "charge", customRender: true},
                            {label: "Rate/Amount", name: "charge_amount", customRender: true},
                            {label: "Type", name: "charge_type", customRender: true},
                            {label: "", name: "action", customRender: true},
                          ]}
                          items={otherChargeEntries || []}
                          customRenders={[
                            {
                              columnName: "charge",
                              render: (item, index) => (
                                <SelectInput
                                  ref={otherChargeInputs[0 + index*1]}
                                  block={true}
                                  rules={[inputValidation.required]}
                                  options={otherChargeList}
                                  value={otherChargeList.filter((charge: any) => charge.value === item.charge_id)}
                                  onChange={(value: any) => {
                                    let entries = otherChargeEntries.map(entry => ({...entry}));
                                    entries[index] = value;
                                    setOtherChargeEntries(entries);
                                  }}
                                />
                              )
                            },
                            {
                              columnName: "charge_amount",
                              render: (item, index) => (
                                <span>{numberFormat(item.charge_amount, 2) || ""}</span>
                              )
                            },
                            {
                              columnName: "charge_type",
                              render: (item, index) => (
                                <span>{capitalize(item.charge_type) || ""}</span>
                              )
                            },
                            {
                              columnName: "action",
                              render: (item, index) => (
                                <div className="d-flex align-center">
                                  <span
                                    className="material-icons cursor-pointer danger-text text-large"
                                    onClick={(e) => {
                                      e.stopPropagation(); 
                                      removeChargeEntry("other", index)
                                    }}
                                    title="Remove Row"
                                  >
                                    close
                                  </span>
                                </div>
                              )
                            }
                          ]}
                        />
                      </div>
                      <div className="d-flex justify-end mt-2">
                        <button
                          className="btn small bg-primary"
                          onClick={(e) => {
                            e.preventDefault();
                            addChargeRow("other");
                          }}
                        >
                          <span className="material-icons">add</span> Add New Row
                        </button>
                      </div>
                    </div>
                    <div className="col-12 col-md-5 col-sm-8">
                      <Table
                        key={2}
                        noHeaderRow
                        noExport
                        noSearch
                        noTitle
                        hideIndex
                        tableCellClassName="w-50"
                        columns={[
                          {name: "label"},
                          {name: "value", customRender: true},
                        ]}
                        items={transactionSummary}
                        customRenders={[
                          {
                            columnName: "value",
                            render: (item: any) => <span className={item.className}>{item.value}</span>
                          },
                        ]}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div>
        {formData?.document_type &&
        <div className="card-footer d-flex justify-center">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.expenseTransaction?.expense_transaction_id ? "Save" : `${formData?.document_type === "bill" ? "Add Expense Bill" : "Add Expense"}`}
          </button>
        </div>}
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TransactionCreate;
