import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Axios from './Axios';
import { baseUrl } from '../utils/constants/constants';

interface requestProps {
  url: string,
  method: string,
  data?: any,
  params?: any, 
  headers?: any
}

const getErrorMessage = (error: any) => {
  let errorMessage = "Something not correct!";
  if (error?.message === "canceled") {
    // return alert?.close();
    return;
  }
  if (error.response) {
    const statusCode = parseInt(error.response.status);
    switch (statusCode) {
      case 400: //Bad Request - The request cannot be fulfilled due to multiple errors
        {
          let data = error.response.data;
          if(data.errors){
            let errors: any[] = [];
            Object.keys(data.errors).forEach((e, i) => errors.push(data.errors[e]));
            errorMessage = errors.join("\n");
          }
        }
        break;
      case 401:
        {
          errorMessage = "Not Authorized!";
        }
        break;
      case 403:
        {
          let data = error.response.data;
          if (data.error) {
            errorMessage = data.error;
          }
        }
        break;
      case 404:
        {
          errorMessage = "The requested resource was not found!";
        }
        break;
      case 417: //Expectation Failed
        {
          let data = error.response.data;
          if (data.errors) {
            errorMessage = data.errors;
          }
        }
        break;
      case 422:
        {
          // validation errors
          let data = error.response.data;
          if (data.error) {
            errorMessage = data.error;
          } else {
            let errors: any[] = [];
            Object.keys(data).forEach((e, i) => errors.push(data[e][0]));
            errorMessage = errors.join("\n");
          }
        }
        break;
    }
  } else if (error.request) {
    errorMessage = "Network connectivity error!";
  }

  // if (alert) {
  //   alert.showError(message);
  // }
  return errorMessage;
}

const axiosBaseQuery = ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers }: requestProps) => {
    try {
      const result = await Axios({
        url: baseUrl + url,
        method,
        data,
        params,
        headers,
      })
      return { data: result.data }
    } catch (axiosError: any) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          statusText: err.response?.statusText,
          data: err.response?.data || err.message,
          errorMessage: getErrorMessage(err),
        },
      }
    }
  }

export const AppService = createApi({
  reducerPath: 'AppService',
  // baseQuery: fetchBaseQuery({
  //   baseUrl: baseUrl,
  //   credentials: 'include',
  //   // headers: {Authorization: `Bearer ${TOKEN}`},
  // }),
  baseQuery: axiosBaseQuery({
    baseUrl: baseUrl,
  }),
  // keepUnusedDataFor: 5,
  tagTypes: [
    'App',
    'Trips', 'GetTrips', 'GetTrip', 'GetTripToUpdate', //Trips
    'Expenses', 'GetExpenses', 'GetExpense', 'GetExpenseToUpdate', //Expenses
    'Payments', 'GetPayments', 'GetPayment', 'GetPaymentToUpdate', //Payments
    'Vehicles', 'GetVehicles', 'GetVehicle', 'GetVehicleToUpdate', //Vehicles
    'Employees', 'GetEmployees', 'GetEmployee', 'GetEmployeeToUpdate', //Employees
    'Routes', 'GetRoutes', 'GetRoute', 'GetRouteToUpdate', //Routes
    'Customers', 'GetCustomers', 'GetCustomer', 'GetCustomerToUpdate', //Customers
    'Cargos', 'GetCargos', 'GetCargo', 'GetCargoToUpdate', //Cargos
    'Reports', 'GetSummaryReport', 'GetTripsReport', 'GetExpensesReport', 'GetIncomeReport', 'GetIncomeAndExpenseReport', 'GetUserActivitiesReport', //Reports
    'ExpenseCategories', 'GetExpenseCategories', 'GetExpenseCategory', 'GetExpenseCategoryToUpdate', //ExpenseCategories
    'ExpenseCategoryItems', 'GetExpenseCategoryItems', 'GetExpenseCategoryItem', 'GetExpenseCategoryItemToUpdate', //ExpenseCategoryItems
    'Roles', 'GetRoles', 'GetRole', 'GetRoleToUpdate', 'GetRolePermissions', //Roles
    'Designations', 'GetDesignations', 'GetDesignation', 'GetDesignationToUpdate', //Designations
    'Companies', 'GetCompanies', 'GetCompany', 'GetCompanyToUpdate', //Companies
    'Users', 'GetUsers', 'GetUser', 'GetUserToUpdate', //Users
    'Permissions', 'GetPermissions', 'GetPermission', 'GetPermissionToUpdate', //Permissions
    'PaymentTransactions', 'GetPaymentTransactions', 'GetPaymentTransaction', 'GetPaymentTransactionToUpdate', 'GetPaymentTripDetails', //PaymentTransactions
    'ExpenseTransactions', 'GetExpenseTransactions', 'GetExpenseTransaction', 'GetExpenseTransactionToUpdate', 'GetExpenseTransactionDetails', //ExpenseTransactions
    'Vendors', 'GetVendors', 'GetVendor', 'GetVendorToUpdate', //Vendors
    'Accounts', 'GetAccounts', 'GetAccount', 'GetAccountToUpdate', //Accounts
    'Journals', 'GetJournals', 'GetJournal', 'GetJournalToUpdate', //Journals
    'JournalEntries', 'GetJournalEntries', 'GetJournalEntry', 'GetJournalEntryToUpdate', //JournalEntries
    'Vouchers', 'GetVouchers', 'GetVoucher', 'GetVoucherToUpdate', //Vouchers
    'AccountingReports', 'GetBalanceSheet', 'GetTrialBalance', 'GetGeneralLedger', 'GetCashFlow', 'GetProfitAndLoss', 'GetMovementOfEquity', //Accounting Reports
    'Charges', 'GetCharges', 'GetCharge', 'GetChargeToUpdate', //Charges
    'Currencies', 'GetCurrencies', 'GetCurrency', 'GetCurrencyToUpdate', //Currencies
    'GeneralConfigs', 'GetGeneralConfigs', 'GetGeneralConfig', 'GetGeneralConfigToUpdate', //GeneralConfigs
  ],
  endpoints: () => ({}),
});