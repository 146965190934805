import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../components/input";

import { formatDateForDb, getDatesOptions, getModulePermissions, numberFormat} from "../../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { capitalize } from './../../../utils/helpers/helpers';
import ExpenseList from "../../expenses/ExpenseList";
import { useGetGeneralLedgerQuery } from "../../../services/accounting/AccountingReportService";
import moment from "moment";
import { Modal } from "../../../components/modal";
interface GeneralLedgerProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  vendor?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const GeneralLedger = (props: GeneralLedgerProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetGeneralLedgerQuery({
    ...filters,
  });

  const [reportData, setReportData] = useState<any[]>([]);
  const [toggleAccounts, setToggleAccounts] = useState<any[]>([]);
  const [reportDetailType, setReportDetailType] = useState("summarized");
  const [reloadDataCounts, setReloadDataCounts] = useState(0);

  const [accountingMethods] = useState([
    {label: "Accrual", value: "accrual"},
    {label: "Cash", value: "cash"},
  ]);
  
  const [reportDetailTypes] = useState([
    {label: "Summarized", value: "summarized"},
    {label: "Deatailed", value: "detailed"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const getTitleObject = (title: string, level: string = "") => {
    return {
      account_name: capitalize(title),
      isTitle: true,
      [`isSection${capitalize(level)}Title`]: true,
    }
  }

  const getTotalObject = (totalObject: {debit: any, credit: any}, label: string, level: string = "") => {
    return {
      account_name: capitalize(label),
      account_code: "",
      net_debit: totalObject.debit,
      net_credit: totalObject.credit,
      [`isSection${capitalize(level)}Total`]: true,
    }
  }

  const getCategoryAccounts = (category: any) => {
    let accounts = data?.accounts?.filter((account: any) => account.account_category_id == category) || [];

    return accounts;
  }

  const getTotal = () => {
    let totalObject: any = {};
    ["debit", "credit"]?.forEach((field: any) => {
      totalObject[field] = (data?.accounts || []).reduce((acc: number, item: any) => {
          return acc + (field == "debit" ? parseFloat(item.net_debit) : parseFloat(item.net_credit));
      }, 0);
    })

    totalObject.debit = totalObject.debit?.toFixed(2);
    totalObject.credit = totalObject.credit?.toFixed(2);
    return totalObject;
  }

  const toggleEntries = (accountId: any) => {;
    let accounts = toggleAccounts.includes(accountId) ? toggleAccounts.filter((account_id: any) => account_id != accountId) : [...toggleAccounts, accountId];
    setToggleAccounts(accounts);
    setReloadDataCounts(reloadDataCounts + 1);
  }

  const populateData = () => {
    let accounts = toggleAccounts;
    let rdata: any[] = [];
    let combinedData = data?.accounts?.map((account: any) => [
      accounts.includes(account.account_id) ? {...account, closing_net_debit: "", closing_net_credit: "", total_debit: "", total_credit: "", account_balance: ""} : account,
      ...(accounts.includes(account.account_id) ? (account.journal_entries || []) : [])
    ]) || [];
    combinedData.map((records: any) => {
      rdata = [
        ...rdata,
        ...records,
      ]
    })
    setReportData(rdata);
  }

  useEffect(() => {
    populateData();
  }, [reloadDataCounts])

  useEffect(() => {
    reportDetailType === "summarized" && setToggleAccounts([]);
    reportDetailType === "detailed" && setToggleAccounts(data?.accounts?.map((account: any) => account.account_id) || []);
    setReloadDataCounts(reloadDataCounts + 1);
  }, [data])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  const columns = [
    {
      label: "Date",
      name: "transaction_date",
      customRender: true,
      valueGetter: (item: any) =>  item.account_category_id ? (
        item.parent_account_name ?
        `${capitalize(item.parent_account_name)} - (${capitalize(item.account_name)})` :
        `${capitalize(item.account_name)}${item.child_accounts_count > 0 ? ` - (Parent)` : ""}` 
      ): (item.is_balance ? `AS On ${moment(item.transaction_date).format("MMM DD, YYYY")}` : moment(item.transaction_date).format("MMM DD, YYYY")),
      colSpan: (item: any, index: number, column: any) => item.account_category_id && 1,
      // className: (item: any, index: number, column: any) => item.account_category_id ? "bg-secondary" : "",
      flex: (item: any, index: number, column: any) => item.account_category_id ? 1 : 1,
    },
    {
      label: "Account",
      name: "account_name",
      customRender: true,
      valueGetter: (item: any) =>  item.account_category_id ? "" : capitalize(item.account_name),
    },
    // {label: "Account Code", name: "account_code", dataClassName: "text-italic secondary-text"},
    {label: "Description", name: "description", customRender: true},
    {
      label: "Debit",
      name: "debit",
      money: true,
      customRender: true,
      valueGetter: (item: any) => (item.debit == 0 || item.closing_net_debit == 0) ? "" : numberFormat(item.account_category_id ? item.closing_net_debit : item.debit, 2, true),
    },
    {
      label: "Credit",
      name: "credit",
      money: true,
      customRender: true,
      valueGetter: (item: any) => ((item.credit == 0 || item.closing_net_credit == 0)  && !(item.is_balance && item.debit == 0)) ? "" : numberFormat(item.account_category_id ? item.closing_net_credit : item.credit, 2, true),
    },
    {
      label: "Balance",
      name: "account_balance",
      money: true,
      customRender: true,
      show: reportDetailType === "summarized",
      valueGetter: (item: any) => numberFormat(item.account_balance, 2, true),
    },
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Balance Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.monthly_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              monthly_report_from_date: value?.name !== "custom" ? value?.from_date : filters.monthly_report_from_date,
              monthly_report_to_date: value?.name !== "custom" ? value?.to_date : filters.monthly_report_to_date,
              monthly_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Start Date"
            block={true}
            value={new Date(filters.monthly_report_from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        {filters.monthly_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom End Date"
            block={true}
            value={new Date(filters.monthly_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                monthly_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
         <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Report Details"
            block={true}
            options={reportDetailTypes}
            value={reportDetailTypes.find(type => type.value === reportDetailType)}
            onChange={(value) => {
              dispatch(setFilters({
                report_details: value.value,
              }))
              setReportDetailType(value.value)
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Accounting Method"
            block={true}
            options={accountingMethods}
            value={accountingMethods.find(method => method.value === (filters.accounting_method || "accrual"))}
            onChange={(value) => dispatch(setFilters({
              accounting_method: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>{`${capitalize(reportDetailType)} `}General Ledger ({capitalize(filters.accounting_method)} Basis) - <span className="text-small">From {moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  {moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `${`${capitalize(reportDetailType)} `}General Ledger (${capitalize(filters.accounting_method)} Basis) - From ${moment(filters.monthly_report_from_date).format("MMM DD, YYYY")}  To  ${moment(filters.monthly_report_to_date).format("MMM DD, YYYY")}`}
                hideIndex
                // noSearch
                // exportFormats={["pdf"]}
                onRowClick={(item) => item.account_category_id && toggleEntries(item.account_id)}
                // getRowClassName={() => "hover-secondary-500"}
                columns={columns}
                items={reportData || []}
                onSearch={(search) => dispatch(setFilters({search: search}))}
                // pagination={{
                //   ...(data?.pagination || {}),
                //   onPageChange: (page) => dispatch(setFilters({page: page})),
                //   onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
                // }}
                params={{...filters, from_date: formatDateForDb(filters.monthly_report_from_date), to_date: formatDateForDb(filters.monthly_report_to_date)}}
                customRenders={[
                  {
                    columnName: "transaction_date",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{item.account_category_id ? (
                      item.parent_account_name ?
                      <span>{capitalize(item.parent_account_name)} - (<span className="text-bold">{capitalize(item.account_name)}</span>)</span> :
                      <span>
                        <span>{capitalize(item.account_name)}</span>
                        <span>
                          {item.child_accounts_count > 0 && <span> - (<span className="text-bold">Parent</span>)</span>}
                        </span>
                      </span>
                    ) : (item.is_balance ? `AS On ${moment(item.transaction_date).format("MMM DD, YYYY")}` : moment(item.transaction_date).format("MMM DD, YYYY"))}</span>
                  },
                  {
                    columnName: "account_name",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => item.account_category_id ? "" : capitalize(item.account_name)

                  },
                  {
                    columnName: "description",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{!item.account_category_id ? item.description : ""}</span>
                  },
                  {
                    columnName: "debit",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{
                      (reportDetailType === "summarized" && item.account_category_id) ? numberFormat(item.total_debit, 2, true) :
                      ((item.debit == 0 || item.closing_net_debit == 0) ? "" : numberFormat(item.account_category_id ? item.closing_net_debit : item.debit, 2, true))
                    }</span>
                  },
                  {
                    columnName: "credit",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{
                      (reportDetailType === "summarized" && item.account_category_id) ? numberFormat(item.total_credit, 2, true) :
                      (((item.credit == 0 || item.closing_net_credit == 0)  && !(item.is_balance && item.debit == 0)) ? "" : numberFormat(item.account_category_id ? item.closing_net_credit : item.credit, 2, true))
                    }</span>
                  },
                  {
                    columnName: "account_balance",
                    getClassName: (item) => (item.account_category_id ? "bg-secondary border-secondary-500 hover-secondary-500 text-bold" : "") + (item.is_balance ? "text-bold bg-info" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.account_balance, 2, true)}</span>
                  },
                ]}
                // totalColumns={[
                //   { name: "debit" },
                //   { name: "credit" },
                // ]}
                // totalColSpan={3}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default GeneralLedger;
