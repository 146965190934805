import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../components/modal";
import { Table } from "../../components/table";
import PaymentCreate from "./PaymentCreate";

import {
  DateInput,
  SelectInput,
} from "../../components/input";

import { formatDateForDb } from "../../utils/helpers/helpers";
import { numberFormat } from '../../utils/helpers/helpers';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useDeletePaymentMutation, useGetPaymentsQuery } from "../../services/payments/PaymentService";
import { clearAlert, setAlert } from "../../states/AlertState";
import { setFilters as setFilter } from "../../states/FiltersState";
import { clearLocalFilters, setLocalFilters } from "../../states/FiltersLocalState";
import JournalPreview from "../accounting/journals/JournalPreview";
interface PaymentListProps {
  paymentTransaction?: any,
  exportTitle?: string,
  useLocalFilters?: boolean,
  from?: string,
  [rest: string]: any,
}

const PaymentList = (props: PaymentListProps) => {
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetPaymentsQuery({...filters, trip: filters.trip || props.trip?.trip_id});
  const [ deletePaymentRecord ] = useDeletePaymentMutation();

  const [ currentTablePage, setCurrentTablePage ] = useState(1);
  const [ params, setParams ] = useState<any>({
    from_date: moment().startOf("month").format("YYYY-MM-DD"),
    to_date: moment().format("YYYY-MM-DD"),
    page: 1,
    limit: 10,
  });

  const [paymentMethods, setPaymentMethods] = useState<any[]>([]);

  const createNewPayment = (payment: any = undefined) => {
    modal.current?.show(
      `${payment ? "Edit Payment Info" : "New Payment"}`,
      (<PaymentCreate
        paymentID={payment?.payment_id}
        tripID={props.trip?.trip_id}
        paymentTransaction={props.paymentTransaction}
        from={props.from}
        modal={modal}
      />),
    );
  }

  const deletePayment = (payment: any) => {
    modal?.current?.show(
      "Delete Payment",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this payment"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deletePaymentRecord({id: payment?.payment_id, journal: payment?.journal_id})
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Payment successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  const previewJournal = (item: any) => {
    modal.current?.show(
      "Jouranl",
      (
        <JournalPreview
          journalID={item?.journal_id	}
          modal={modal}
        />
      ),
      0,
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.payment_methods && setPaymentMethods(data.payment_methods.map((payment_method: any) => ({label: payment_method.payment_method_name, value: payment_method.payment_method_id})));
  }, [data])

  useEffect(() => {
    return () => {
      props.useLocalFilters && dispatch(clearLocalFilters());
    }
  }, [])

  return (
    <>
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Payment Method"
            block={true}
            clearable
            options={paymentMethods}
            onChange={(value) => dispatch(setFilters({
              payment_method: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Payments"
              exportTitle={props.exportTitle}
              columns={[
                {label: "Date", name: "payment_date"},
                {label: "Payment Method", name: "payment_method_name"},
                {label: "Amount (Tsh)", name: "amount", customRender: true},
                {label: "Attachment", name: "attachment", customRender: true, webOnly: true},
                {label: "Description", name: "comment"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.payments || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                props.paymentTransaction?.transaction_type !== "cash" &&
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewPayment()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                },
                {
                  columnName: "attachment",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      {item.supporting_doc &&
                      <button
                        className="btn small bg-primary cursor-pointer"
                        onClick={() => window.open(item.supporting_doc, "_blank")}
                        title="Attachment"
                      >
                        View
                      </button>}
                    </div>
                  )
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      {props.paymentTransaction?.transaction_type !== "cash" &&
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewPayment(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>}
                      {props.paymentTransaction?.transaction_type !== "cash" &&
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deletePayment(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>}
                      {(props.paymentTransaction && item.journal_id) &&
                      <button
                        className="btn small bg-success cursor-pointer ml-2"
                        onClick={() => previewJournal(item)}
                        title="Preview Journal"
                      >
                        Journal
                      </button>}
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default PaymentList;
