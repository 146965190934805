import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../../components/form";
import {
  SelectInput,
  TextArea,
  TextField
} from "../../../../components/input";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { getValidationRules } from '../../../../utils/helpers/helpers';
import { useDispatch } from "react-redux";
import { useCreateExpenseCategoryItemMutation, useGetExpenseCategoryItemToUpdateQuery } from "../../../../services/settings/expenses/expense-category-items/ExpenseCategoryItemService";
import { clearAlert, setAlert } from "../../../../states/AlertState";

interface ExpenseCategoryItemCreateProps {
  expenseCategoryItemID?: any,
  modal?: MutableRefObject<any>,
}

const ExpenseCategoryItemCreate = (props: ExpenseCategoryItemCreateProps) => {
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetExpenseCategoryItemToUpdateQuery(props.expenseCategoryItemID || -1);
  const [ createExpenseCategoryItem ] = useCreateExpenseCategoryItemMutation();

  const [expenseCategories, setExpenseCategories] = useState<any[]>([]);

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Expense Item Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let FD: any = {};
        Object.keys(formData).forEach((key) => {
         FD[key] = typeof formData[key]?.trim === "function" ? formData[key]?.trim() : formData[key];
        });
        createExpenseCategoryItem({...FD, id: props.expenseCategoryItemID})
        .unwrap()
        .then((response: any) => {
          if (props.expenseCategoryItemID) {
            dispatch(setAlert({type: "success", message: "Expense Item Info has successfully saved", unreplaceable: true}));
          }
          else {
            dispatch(setAlert({type: "success", message: "Expense Item has successfully added", unreplaceable: true}));
          }
          setTimeout(() => {
            props.modal?.current?.hide();
          }, 1500);
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const populateData = (data: any) => {
    setFormData({
      ...data.expense_category_item,
      expense_category: data.expense_category_item?.expense_category_id,
    });
    data?.expense_categories && setExpenseCategories(data.expense_categories.map((expense_category: any) => ({label: expense_category.expense_category_name, value: expense_category.expense_category_id})));
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card scrollable y">
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <SelectInput
                    ref={inputs[0]}
                    label="Expense Category"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    options={expenseCategories}
                    value={expenseCategories.filter(expenseCategory => expenseCategory.value === formData.expense_category)}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category: value?.value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Expense Item Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.expense_category_item_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      expense_category_item_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextArea
                    ref={inputs[2]}
                    label="Description"
                    block={true}
                    value={formData.description || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      description: value
                    })}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            {props.expenseCategoryItemID ? "Save" : "Add Expense Item"}
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ExpenseCategoryItemCreate;
