import { AccountDetails, AccountList } from './accounts';
import { ExpenseItems } from './expense-items';
import { ExpenseTransactionList } from './expense-transactions';
import { JournalList } from './journals';
import { TransactionList } from './payment-transactions';
import { BalanceSheet, CashFlow, GeneralLedger, MovementOfEquity, ProfitAndLoss, TrialBalance } from './reports';
import UnprocessedPaymentTripList from './UnprocessedPaymentTripList';
import { VendorDetails, VendorList } from './vendors';

const AccountingRoutes = [
  {
    url: "/uprocessed-payment-trips",
    component: UnprocessedPaymentTripList,
    permission: "accounting_unprocessed_payment_trips",
    props: {}
  },
  {
    url: "/transactions",
    component: TransactionList,
    permission: "accounting_transactions",
    props: {}
  },
  {
    url: "/expenses/list",
    component: ExpenseTransactionList,
    permission: "accounting_expense_list",
    props: {}
  },
  {
    url: "/expenses/vendors",
    component: VendorList,
    permission: "accounting_vendors",
    props: {}
  },
  {
    url: "/expenses/vendors/details/:vendorID",
    component: VendorDetails,
    // permission: "customers_view",
    permission: "accounting_vendors",
    props: {}
  },
  {
    url: "/expenses/expense-items",
    component: ExpenseItems,
    permission: "accounting_expense_items",
    props: {}
  },
  {
    url: "/advanced/chart-of-accounts",
    component: AccountList,
    permission: "accounting_chart_of_accounts",
    props: {}
  },
  {
    url: "/advanced/chart-of-accounts/details/:accountID",
    component: AccountDetails,
    permission: "accounting_chart_of_accounts",
    props: {}
  },
  {
    url: "/advanced/journal-entry",
    component: JournalList,
    permission: "accounting_journal_entry",
    props: {}
  },
  {
    url: "/reports/balance-sheet",
    component: BalanceSheet,
    permission: "accounting_reports",
    props: {}
  },
  {
    url: "/reports/trial-balance",
    component: TrialBalance,
    permission: "accounting_reports",
    props: {}
  },
  {
    url: "/reports/general-ledger",
    component: GeneralLedger,
    permission: "accounting_reports",
    props: {}
  },
  {
    url: "/reports/cash-flow",
    component: CashFlow,
    permission: "accounting_reports",
    props: {}
  },
  {
    url: "/reports/profit-and-loss",
    component: ProfitAndLoss,
    permission: "accounting_reports",
    props: {}
  },
  {
    url: "/reports/movement-of-equity",
    component: MovementOfEquity,
    permission: "accounting_reports",
    props: {}
  },
]

export default AccountingRoutes;