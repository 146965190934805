import { useEffect, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../../components/modal";
import { Table } from "../../../../components/table";
import ChargeCreate from "./ChargeCreate";

import { useDispatch, useSelector } from "react-redux";
import { useDeleteChargeMutation, useGetChargesQuery } from "../../../../services/settings/general/ChargeService";
import { clearAlert, setAlert } from "../../../../states/AlertState";
import { clearFilters, setFilters } from "../../../../states/FiltersState";
import { capitalize, numberFormat } from "../../../../utils/helpers/helpers";
interface ChargeListProps {
  exportTitle?: string,
  accountCategory?: string,
  title?: string,
  [rest: string]: any,
}

const ChargeList = (props: ChargeListProps) => {
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetChargesQuery({...filters, account_category: props.accountCategory});
  const [ deleteChargeRecord ] = useDeleteChargeMutation();

  const createNewCharge = (charge: any = undefined) => {
    modal.current?.show(
      `${charge ? "Edit Charge Info" : "New Charge"}`,
      (<ChargeCreate
        chargeID={charge?.charge_id}
        accountCategory={props.accountCategory}
        modal={modal}
      />),
    );
  }

  const deleteCharge = (charge: any) => {
    modal?.current?.show(
      "Delete Charge",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this charge"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteChargeRecord({id: charge?.charge_id, account: charge?.account_id})
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Charge successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title={`${props.title || "Charges"}`}
              exportTitle={props.exportTitle}
              columns={[
                {label: "Charge Category", name: "category", customRender: true},
                {label: "Charge Name", name: "name"},
                {label: "Charge Type", name: "type", customRender: true},
                {label: "Charge Rate/Amount", name: "amount", customRender: true},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.charges || []}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={{
                ...filters,
                from_date: undefined,
                to_date: undefined
              }}
              actions={[
                <button
                  className="btn small with-icon bg-secondary"
                  onClick={() => createNewCharge()}
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>,
              ]}
              customRenders={[
                {
                  columnName: "index",
                  render: (item, index) => <span>{index + 1}</span>
                },
                {
                  columnName: "type",
                  render: (item, index) => <span>{item.type && capitalize(item.type)}</span>
                },
                {
                  columnName: "category",
                  render: (item, index) => <span>{item.category && capitalize(item.category)}</span>
                },
                {
                  columnName: "amount",
                  render: (item, index) => <span>{item.amount && numberFormat(item.amount)}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        className="material-icons secondary-text cursor-pointer ml-2"
                        onClick={() => createNewCharge(item)}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        className="material-icons danger-text cursor-pointer ml-2"
                        onClick={() => deleteCharge(item)}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default ChargeList;
