import { AppService } from '../AppService';

export const ExpenseTransactionService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getExpenseTransactions: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/expense-transactions",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'ExpenseTransactions', 'GetExpenseTransactions'],
    }),
    getExpenseTransaction: builder.query({
        query: (id) => ({
            url: `/expense-transactions/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'ExpenseTransactions', 'GetExpenseTransaction'],
    }),
    getExpenseTransactionToUpdate: builder.query({
        query: (id) => ({
            url: `/expense-transactions/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'ExpenseTransactions', 'GetExpenseTransactionToUpdate'],
    }),
    getExpenseTransactionDetails: builder.query({
        query: (id) => ({
            url: `/expense-transactions/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'ExpenseTransactions', 'GetExpenseTransactionDetails'],
    }),
    createExpenseTransaction: builder.mutation({
        query: (data = {}) => ({
            url: `/expense-transactions/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['ExpenseTransactions'],
    }),
    deleteExpenseTransaction: builder.mutation({
        query: (id) => ({
            url: `/expense-transactions/${id}`,
            method: "DELETE",
        }),
        invalidatesTags: ['GetExpenseTransactions'],
    }),
  }),
});

export const {
    useGetExpenseTransactionsQuery,
    useGetExpenseTransactionQuery,
    useGetExpenseTransactionToUpdateQuery,
    useGetExpenseTransactionDetailsQuery,
    useCreateExpenseTransactionMutation,
    // useUpdateExpenseTransactionMutation,
    useDeleteExpenseTransactionMutation,
} = ExpenseTransactionService;
