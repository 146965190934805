import { useEffect, useMemo, useRef, useState } from "react";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { Table } from "../../../components/table";
import AccountCreate from "./AccountCreate";

import {
  SelectInput,
} from "../../../components/input";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { setFilters } from "../../../states/FiltersState";
import { getModulePermissions, getNoPermissionMessage, hasPermission } from "../../../utils/helpers/helpers";
import { useDeleteAccountMutation, useGetAccountsQuery } from "../../../services/accounting/AccountService";
interface AccountListProps {
}

const AccountList = (props: AccountListProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetAccountsQuery(filters);
  const [ deleteAccountRecord ] = useDeleteAccountMutation();

  const [accountCategories, setAccountCategories] = useState<any[]>([]);
  const [accountTypes, setAccountTypes] = useState<any[]>([]);
  const [accountCategoryTypes, setAccountCategoryTypes] = useState<any[]>([]);

  const permission = useMemo(
    () => getModulePermissions("accounts", user?.permissions),
    [user]
  );

  const createNewAccount = (account: any = undefined) => {
    modal.current?.show(
      `${account ? "Edit Account Info" : "New Account"}`,
      (<AccountCreate
        accountID={account?.account_id}
        modal={modal}
      />),
    );
  }

  const deleteAccount = (account: any) => {
    modal?.current?.show(
      "Delete Account",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this account"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteAccountRecord(account?.account_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Account successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  useEffect(() => {
    filters.category ?
    setAccountCategoryTypes(accountTypes.filter((type: any) => type.account_category_id == filters.category)) :
    setAccountCategoryTypes(accountTypes);
  }, [filters.category, accountTypes])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    data?.account_categories && setAccountCategories(data.account_categories.map((category: any) => ({label: `${category.account_category_name}` , value: category.account_category_id, ...category})));
    data?.account_types && setAccountTypes(data.account_types.map((type: any) => ({label: `${type.account_type_name}` , value: type.account_type_id, ...type})));
  }, [data])

  return (
    <>
      {/* {patientList?.data?.data?.map((account: any) => (<div>Hey there</div>))} */}
      <div className="row justify-center shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Account Category"
            block={true}
            clearable
            options={accountCategories}
            value={accountCategories.find(category => category.value === filters.category)}
            onChange={(value) => dispatch(setFilters({
              category: value?.value
            }))}
          />
        </div>
        <div className="col-12 col-md-4 col-sm-6">
          <SelectInput
            placeholder="Account Types"
            block={true}
            clearable
            options={accountCategoryTypes}
            value={accountCategoryTypes.find(type => type.value === filters.type)}
            onChange={(value) => dispatch(setFilters({
              type: value?.value
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Accounts"
              onRowClick={(item) => {
                // permission?.view ?
                navigate(`/accounting/advanced/chart-of-accounts/details/${item.account_id}`)
                // :
                // dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "account")}))
              }}
              columns={[
                {label: "Account Name", name: "account_name"},
                {label: "Account Code", name: "account_code"},
                {label: "Account Category", name: "account_category_name"},
                {label: "Account Type", name: "account_type_name"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.accounts || []}
              // permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                <button
                  // className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  className={`btn small with-icon bg-secondary`}
                  onClick={() =>
                    // permission?.add ?
                    createNewAccount()
                    // :
                    // dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "account")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>
              ]}
              customRenders={[
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        // className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        className={`material-icons cursor-pointer primary-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // permission?.view ?
                          navigate(`/accounting/advanced/chart-of-accounts/details/${item.account_id}`)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "account")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      {item.company_id != 0 ? (<>
                      <span
                        // className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        className={`material-icons cursor-pointer ml-2 secondary-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // permission?.update ?
                          createNewAccount(item)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "account")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        // className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        className={`material-icons cursor-pointer ml-2 danger-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // permission?.delete ?
                          deleteAccount(item)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "account")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span></>) : (
                        <span className="grey-text ml-2"></span>
                      )}
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default AccountList;
