export const appName = "MaiproFleet";

export const appVersion = "2.0";

// export const baseUrl = "http://localhost/mf/api";
// export const baseUrl = "http://192.168.43.63/mf/api";
// export const baseUrl = "http://192.168.100.211/mf/api";
// export const baseUrl = "http://prod-test.local/api";
// export const baseUrl = "https://secure.maiprofleet.com:8008/api";
// export const baseUrl = "https://v2.maiprofleet.com:8008/api";
export const baseUrl = "https://demo.maiprofleet.com:8008/api";

export const primaryColor = "rgb(22, 40, 79)";