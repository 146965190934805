import { useEffect, useMemo, useRef } from "react";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { Table } from "../../../components/table";
import JournalCreate from "./JournalCreate";

import {
  DateInput,
} from "../../../components/input";

import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { setFilters } from "../../../states/FiltersState";
import { formatDateForDb, getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat, padStart } from "../../../utils/helpers/helpers";
import { useDeleteJournalMutation, useGetJournalsQuery } from "../../../services/accounting/JournalService";
import JournalPreview from "./JournalPreview";
interface JournalListProps {
}

const JournalList = (props: JournalListProps) => {
  const user = useSelector((state: any) => state.userState);
  const filters = useSelector((state: any) => state.filtersState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetJournalsQuery({...filters, journal_type: "manual"});
  const [ deleteJournalRecord ] = useDeleteJournalMutation();

  const permission = useMemo(
    () => getModulePermissions("journals", user?.permissions),
    [user]
  );

  const createNewJournal = (journal: any = undefined) => {
    modal.current?.show(
      `${journal ? "Edit Journal Info" : "New Journal"}`,
      (<JournalCreate
        journalID={journal?.journal_id}
        modal={modal}
      />),
    );
  }

  const deleteJournal = (journal: any) => {
    modal?.current?.show(
      "Delete Journal",
      <ConfirmationDialog
        promptMessage="Are you sure want to delete this journal"
        okayText="Delete"
        okayClassName="bg-danger"
        onOkay={() => {
          dispatch(setAlert({type: "progress", message: "Deleting..."}));
          modal?.current?.hide();
          deleteJournalRecord(journal?.journal_id)
          .unwrap()
          .then((response: any) => {
            dispatch(setAlert({type: "success", message: "Journal successfully deleted"}));
          })
          .catch((error: any) => {
            dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
          })
        }}
        onCancel={() => {
          modal?.current?.hide();
        }}
      />
    )
  };

  const previewJournal = (item: any) => {
    modal.current?.show(
      "Jouranl",
      (
        <JournalPreview
          journalID={item?.journal_id	}
          modal={modal}
        />
      ),
      0,
    );
  }

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1 justify-center">
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date From"
            block={true}
            value={new Date(filters.from_date)}
            onChange={(value) => {
              dispatch(setFilters({
                from_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Date To"
            block={true}
            value={new Date(filters.to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          <div>
            <Table
              loading={isLoading}
              title="Journals"
              onRowClick={(item) => {
                previewJournal(item);
                // permission?.view ?
                // navigate(`/accounting/advanced/chart-of-accounts/details/${item.account_id}`)
                // :
                // dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "account")}))
              }}
              columns={[
                {label: "Journal Date", name: "journal_date"},
                {label: "Journal Name", name: "journal_name"},
                {label: "Journal #", name: "journal_number", customRender: true},
                {label: "Reference #", name: "reference"},
                {label: "Notes", name: "notes"},
                {label: "Currency", name: "currency_code"},
                {label: "Total", name: "amount", customRender: true},
                {label: "Created By", name: "created_by_name"},
                {label: "Action", name: "action", customRender: true, webOnly: true},
              ]}
              items={data?.journals || []}
              // permission={permission}
              onSearch={(search) => dispatch(setFilters({search: search}))}
              pagination={{
                ...(data?.pagination || {}),
                onPageChange: (page) => dispatch(setFilters({page: page})),
                onPageLimitChange: (limit) => dispatch(setFilters({limit: limit})),
              }}
              params={filters}
              actions={[
                <button
                  // className={`btn small with-icon bg-secondary ${!permission?.add && "disabled"}`}
                  className={`btn small with-icon bg-secondary`}
                  onClick={() =>
                    // permission?.add ?
                    createNewJournal()
                    // :
                    // dispatch(setAlert({type: "error", message: getNoPermissionMessage("add", "journal")}))
                  }
                  key={0}
                >
                  <span className="material-icons">add</span>
                  <span>Add</span>
                </button>
              ]}
              customRenders={[
                {
                  columnName: "journal_number",
                  render: (item) => <span>{padStart(item.journal_number, 4, "0")}</span>
                },
                {
                  columnName: "amount",
                  render: (item) => <span>{item.amount && numberFormat(item.amount, 2)}</span>
                },
                {
                  columnName: "action",
                  render: (item) => (
                    <div className="d-flex flex-row align-center">
                      <span
                        // className={`material-icons cursor-pointer primary-text ${!permission?.view && "disabled"}`}
                        className={`material-icons cursor-pointer primary-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          previewJournal(item);
                          // permission?.view ?
                          // navigate(`/journaling/advanced/chart-of-journals/details/${item.journal_id}`)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("view", "journal")}))
                        }}
                        title="View Details"
                      >
                        visibility
                      </span>
                      <span
                        // className={`material-icons cursor-pointer ml-2 secondary-text ${!permission?.update && "disabled"}`}
                        className={`material-icons cursor-pointer ml-2 secondary-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // permission?.update ?
                          createNewJournal(item)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("edit", "journal")}))
                        }}
                        title="Edit Info"
                      >
                        edit
                      </span>
                      <span
                        // className={`material-icons cursor-pointer ml-2 danger-text ${!permission?.delete && "disabled"}`}
                        className={`material-icons cursor-pointer ml-2 danger-text`}
                        onClick={(e) => {
                          e.stopPropagation(); 
                          // permission?.delete ?
                          deleteJournal(item)
                          // :
                          // dispatch(setAlert({type: "error", message: getNoPermissionMessage("delete", "journal")}))
                        }}
                        title="Delete Record"
                      >
                        delete
                      </span>
                    </div>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default JournalList;
