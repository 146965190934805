import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Document,
  Font,
  Image,
  Page,
  PDFDownloadLink,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

// import fontRegular from "../../fonts/Custom-Regular.ttf";
// import fontItalic from "../../fonts/Custom-Italic.ttf";
// import fontBold from "../../fonts/Custom-Bold.ttf";

import Header from "../../../components/pdf/Header";
import Footer from "../../../components/pdf/Footer";
import PDFDocTable from "../../../components/pdf/PDFDocTable";

import { appName } from "../../../utils/constants/constants";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { capitalize, numberFormat, padStart } from "../../../utils/helpers/helpers";
import { useGetJournalQuery } from "../../../services/accounting/JournalService";
import PDFDetails from "../../../components/pdf/PDFDetails";

Font.register({
  family: "Custom",
  fonts: [
    // { src: fontRegular },
    // { src: fontItalic, fontStyle: "italic" },
    // { src: fontBold, fontWeight: 700 },
  ],
});

export const JournalPDF = (props: any) => {
  const { data, user } = props || {};
  const config = props.user?.configuration || {};

  
  return (
    <Document title="Journal" creator={appName} producer={appName}>
      <Page
        size="A4"
        style={{
          width: "100%",
          backgroundColor: "white",
          paddingHorizontal: 24,
          paddingTop: 24,
          paddingBottom: 35,
        }}
        orientation="portrait"
      >
      <Header
        title="Journal"
        titleTextStyle={styles.text}
        contactsTextStyle={styles.text}
        user={user}
      />
      <View style={[{marginBottom: 24}]}>
        <View style={[{width: "100%"}]}>
          <PDFDetails
            columns={2}
            fields={[
              {label: "Journal Name", getValue: (item: any) => capitalize(item?.journal_name)},
              {label: "Journal Date", name: "journal_date"},
              {label: "Journal #", getValue: (item: any) => padStart(item?.journal_number, 4, "0")},
              {label: `Total (${data.currency_code})`, getValue: (item: any) => numberFormat(item?.amount, 2)},
              {label: "Notes", name: "notes"},
              {label: "Reference", name: "reference"},
            ]}
            item={data}
          />
        </View>
      </View>

      <PDFDocTable
        // title="Items"
        columns={[
          {label: "Account", getValue: (item: any) => capitalize(item?.account_name)},
          {label: `Debit${data.currency_code ? ` (${data.currency_code})` : ""}`, name: "debit", getValue: (item: any) => item.debit != 0 ? numberFormat(item?.debit, 2) : ""},
          {label: `Credit${data.currency_code ? ` (${data.currency_code})` : ""}`, name: "credit", getValue: (item: any) => item.credit != 0 ? numberFormat(item?.credit, 2) : ""},
        ]}
        items={data?.journal_entries || []}
        hideIndex
        totalColumns={[
          { name: "debit" },
          { name: "credit" }
        ]}
        totalColSpan={1}
      />
      
      <Footer
        style={styles.text}
        user={user}
        render={({ pageNumber, totalPages }: any) =>
          `${pageNumber} / ${totalPages}`
        }
      />
      </Page>
    </Document>
  );
}

const JournalPreview = (props: any) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const alert = useRef<any>();
  
  const [docData, setDocData] = useState<any>();
  const [transactionSummary, setTransactionSummary] = useState<any>([]);
  
  const { data, isLoading, isSuccess, isError, error} = useGetJournalQuery(props.journalID);
  
  const populateData = (data: any) => {
    setDocData({...data.journal});
  }
  
  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);
  
  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])
  
  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);
  
  useEffect(() => {
    data && populateData(data);
  }, [data])
  
  return (
    <div className="card">
      <div className="card-header with-actions">
        <div>Journal</div>
        <div className="card-actions">
        {docData ? (
          <PDFDownloadLink
          document={
            <JournalPDF data={docData} user={user} {...props} />
          }
          fileName={`Journal_${docData.journal_number}`}
          children={() => (
            <button type="button" className="btn secondary small">
              Download
            </button>
          )}
          />
        ) : null}
        </div>
      </div>
      <div className="card-body">
        {docData ? (
          <PDFViewer className="pdf-viewer">
            <JournalPDF data={docData} user={user} {...props} />
          </PDFViewer>
        ) : null}
      </div>
      <div className="card-footer d-flex flex-row justify-center">
        <button
          type="button"
          className="btn outlined"
          onClick={() => {
            props.modal.hide();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  row: {
    flexDirection: "row",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default JournalPreview;