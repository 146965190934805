import React, {  useEffect, useMemo, useRef, useState } from "react";
import { Form } from "../../../components/form";
import {
  FileInput,
  TextArea,
  TextField
} from "../../../components/input";
import { ConfirmationDialog, Modal } from "../../../components/modal";
import { getValidationRules } from '../../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { useCreateCompanyMutation, useGetCompanyToUpdateQuery } from "../../../services/settings/companies/CompanyService";
import noImageAvatar from "../../../assets/images/system/no-image-avatar.jpeg";

interface CompanyCreateProps {

}

const CompanyCreate = (props: CompanyCreateProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const inputValidation = getValidationRules(); 

  const form = useRef<any>();
  const modal = useRef<any>();
  const inputs: any = useMemo(
    () => Array.from({length: 8}).map(() =>  React.createRef()),
    []
  )

  const [formData, setFormData] = useState<any>({});
  const { data, isLoading, isSuccess, isError, error } = useGetCompanyToUpdateQuery(-2);
  const [ createCompany ] = useCreateCompanyMutation();

  const save = () => {
    if(!form?.current?.validate()) {
      return dispatch(setAlert({type: "error", message: "Please fill all required fields."}));
    }
    modal?.current?.show(
      "Confirm Save",
      <ConfirmationDialog
      promptMessage="Are you sure want to save this Company Info"
      onOkay={() => {
        dispatch(setAlert({type: "progress", message: "Processing..."}));
        modal?.current?.hide();
        let formDt: any = {
          ...formData,
          company_phone_number: formData.phone_number,
          company_email: formData.email,
          company_physical_address: formData.physical_address,
        }
        let FD: any = {};
        Object.keys(formDt).forEach((key) => {
          if(formDt[key]){
            FD[key] = typeof formDt[key]?.trim === "function" ? formDt[key]?.trim() : formDt[key];
          }
        });
        let FrmDt = new FormData();
        Object.keys(FD).forEach((key) => {
          FrmDt.append(key, FD[key]);
        });
        createCompany({data: FrmDt, id: -2})
        .unwrap()
        .then((response: any) => {
          dispatch(setAlert({type: "success", message: "Company Info has successfully saved", unreplaceable: true}));
        })
        .catch((error: any) => {
          dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
        })
      }}
      onCancel={() => {
        modal?.current?.hide();
      }}
    />
    )
  }

  const setImage = (name: any, value: any, ref: any) => {
    setFormData({
      ...formData,
      [name]: value[0],
    })
    setTimeout(() => {
      URL.revokeObjectURL(ref.current.src);
      ref.current.src = URL.createObjectURL(value[0]);
    }, 1)
  };

  const populateData = (data: any) => {
    setFormData({
      ...data.company,
    });
  }

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess &&  dispatch(clearAlert());
  }, [isSuccess]);

  useEffect(() => {
    data && populateData(data);
  }, [data])

  return (
    <>
      <div className="card">
        <div className="card-header">Company Details</div>
        <div className="card-body">
          <div>
            <Form ref={form}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[0]}
                    label="Company Name"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.company_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      company_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[1]}
                    label="Phone No"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.phone_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      phone_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[2]}
                    label="Email"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.email || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      email: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    ref={inputs[3]}
                    label="Physical Address"
                    block={true}
                    requiredDecorator={true}
                    rules={[inputValidation.required]}
                    value={formData.physical_address || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      physical_address: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="P.O Box"
                    block={true}
                    value={formData.pobox || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      pobox: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="TIN"
                    block={true}
                    value={formData.tin_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      tin_number: value
                    })}
                  />
                </div>
              </div>
              <div className="divider my-4" />

              <div className="grey-text text-large my-3">Company Bank Details</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account No"
                    block={true}
                    value={formData.bank_account_number || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_account_number: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Account Name"
                    block={true}
                    value={formData.bank_account_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_account_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Bank Name"
                    block={true}
                    value={formData.bank_name || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_name: value
                    })}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <TextField
                    label="Bank Branch"
                    block={true}
                    value={formData.bank_branch || ""}
                    onChange={(value) => setFormData({
                      ...formData,
                      bank_branch: value
                    })}
                  />
                </div>
              </div>
              <div className="divider my-4" />
              
              <div className="grey-text text-large my-3">Company Official Images</div>
              <div className="row">
                <div className="col-12 col-md-4">
                  <div className="avatar large tile mx-auto mb-2">
                    <img
                      ref={inputs[4]}
                      src={inputValidation.url(formData.logo) ? formData.logo : noImageAvatar}
                      alt="Logo"
                    />
                  </div>
                  <FileInput
                    accept="image/*"
                    label="Company Logo"
                    block={true}
                    renderEndIcon={() => (
                      <span className="material-icons">attachment</span>
                    )}
                    onChange={(value) => setImage("logo", value, inputs[4])}
                  />
                </div>
                <div className="col-12 col-md-4">
                  <div className="avatar large tile mx-auto mb-2">
                    <img
                      ref={inputs[5]}
                      src={inputValidation.url(formData.stamp) ? formData.stamp : noImageAvatar}
                      alt="Stamp"
                    />
                  </div>
                  <FileInput
                    accept="image/*"
                    label="Company Stamp"
                    block={true}
                    renderEndIcon={() => (
                      <span className="material-icons">attachment</span>
                    )}
                    onChange={(value) => setImage("stamp", value, inputs[5])}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
        <div className="card-footer d-flex flex-row">
          <button
            className="btn bg-secondary"
            onClick={() => save()}
          >
            Save
          </button>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CompanyCreate;
