import { AppService } from '../AppService';

export const JournalService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getJournals: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/accounting/journals",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Journals', 'GetJournals'],
    }),
    getJournal: builder.query({
        query: (id) => ({
            url: `/accounting/journals/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Journals', 'GetJournal'],
    }),
    getJournalToUpdate: builder.query({
        query: (id) => ({
            url: `/accounting/journals/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Journals', 'GetJournalToUpdate'],
    }),
    createJournal: builder.mutation({
        query: (data = {}) => ({
            url: `/accounting/journals/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data.data,
        }),
       invalidatesTags: ['Journals', 'GetJournal'],
    }),
    deleteJournal: builder.mutation({
        query: (id) => ({
            url: `/accounting/journals/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetJournals', 'GetJournal'],
    }),
    changeStatus: builder.mutation({
        query: (data: {id: any, status: any}) => ({
            url: `/accounting/journals/change-status/${data.id}/${data.status}`,
            method: "GET",
        }),
        invalidatesTags: ['Journals', 'GetJournal'],
    }),
  }),
});

export const {
    useGetJournalsQuery,
    useGetJournalQuery,
    useGetJournalToUpdateQuery,
    useCreateJournalMutation,
    useDeleteJournalMutation,
    useChangeStatusMutation,
} = JournalService;
