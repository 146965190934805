import {
  Font,
  Image,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { numberFormat, stripCommas } from "../../utils/helpers/helpers";

// import fontRegular from "../../fonts/Custom-Regular.ttf";
// import fontItalic from "../../fonts/Custom-Italic.ttf";
// import fontBold from "../../fonts/Custom-Bold.ttf";

Font.register({
  family: "Custom",
  fonts: [
    // { src: fontRegular },
    // { src: fontItalic, fontStyle: "italic" },
    // { src: fontBold, fontWeight: 700 },
  ],
});

const PDFDocTable = (props: any) => {
  const columns = (props.columns || [])
    .filter((e: any) => typeof e.show === "undefined" || e.show);;
  const items = (props.items || []);

  const renderTotal = (data: any, totalColumns: any, columns: any = [], totalColSpan: any = 1) => {
    let totalObjects: any = {};
    totalColumns?.forEach((item: any) => {
      let correspondingColumn = columns.find((val: any) => val.name == item.name);
      totalObjects[item.name] = {
        amount: item.value || data?.reduce((acc: number, curr: any) => {
          return correspondingColumn?.getValue
            ? acc +
                parseFloat(
                  stripCommas(correspondingColumn.getValue(curr)) || 0
                )
            : acc + parseFloat(stripCommas(curr[item.name]) || 0);
        }, 0),
      };
    });
    props.setTotal && props.setTotal(totalObjects);
    return (
      <View style={[styles.tableRow, styles.lightGrey]}>
        <Text
         style={[
           styles.text,
           styles.tableCell,
           {
             flex: totalColSpan,
             textAlign: "center",
             fontWeight: "bold",
           },
         ]}
       >
         Total
       </Text>
       {columns?.map((col: any, i: number) => {
          return (i + (!props.hideIndex ? 2 : 1) > totalColSpan) ? (
            <Text
              key={i}
              style={[
                styles.text,
                styles.tableCell,
                col.style,
                { fontWeight: "bold", flex: col.flex},
              ]}
            >
              {totalObjects[col.name] && numberFormat(totalObjects[col.name]?.amount || 0, 2) }
            </Text>
          ) : null
        })}
     </View>
    );
  };

  return (
    <View style={[styles.table, props.style]}>
      {props.title && <View style={styles.tableRow}>
        <Text
          style={[
            styles.text,
            styles.tableCell,
            styles.lightGrey,
            { textTransform: "uppercase", fontWeight: "bold"},
          ]}
        >
          {props.title}
        </Text>
      </View>}
      {!props.noTitle && <View style={[styles.tableRow, styles.lightGrey]}>
       {!props.hideIndex && <Text
          style={[
            styles.text,
            styles.tableCell,
            {
              flex: 1,
              // textAlign: "center",
              fontWeight: "bold"
            },
          ]}
        >
          S/N
        </Text>}
        {columns?.map((col: any, i: number) => (
          <Text
            key={i}
            style={[
              styles.text,
              styles.tableCell,
              { fontWeight: "bold", flex: col.flex},
            ]}
          >
            {col.label}
          </Text>
        ))}
      </View>}
      {items?.length ? items?.map((item: any, i: number) => (
        <View style={[styles.tableRow, props.getRowStyle && props.getRowStyle(item, i), item.style]} key={i}>
         {!props.hideIndex && <Text
            key={i}
            style={[
              styles.text,
              styles.tableCell,
            ]}
          >
            {i+1}
          </Text>}
          {columns?.map((col: any, j: number) => (
            // col.type !== "image" ?
            <Text
              key={j}
              style={[
                styles.text,
                styles.tableCell,
                col.getStyle && col.getStyle(item, i),
                {flex: col.flex},
              ]}
            >
              {col.getValue ? col.getValue(item, i, props.refItem) : (item[col.name] ||  undefined)}
            </Text>
            // :
            // <View
            //   key={j}
            //   style={[
            //     styles.text,
            //     styles.tableCell,
            //     {flex: col.flex},
            //   ]}
            // >
            //   <Image
            //     src={undefined}
            //     style={{
            //       width: "25",
            //       height: "auto",
            //     }}
            //   />
            // </View>
          ))}
        </View>
      )) :
        <View style={[styles.tableRow]}>
          <Text
            style={[
              styles.text,
              styles.tableCell,
              {textAlign: "center"},
            ]}
          >
            No data
          </Text>
        </View>
      }
      {props.totalColumns && renderTotal(
        props.items || [],
        props.totalColumns,
        columns,
        props.totalColSpan
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  spacer: {
    marginBottom: 4,
  },
  divider: {
    height: 1,
    backgroundColor: "black",
  },
  text: {
    fontSize: 8,
    // fontFamily: "Custom",
  },
  outlined: {
    border: "1pt solid black",
  },
  padded: {
    padding: 5,
  },
  grey: {
    backgroundColor: "grey",
  },
  lightGrey: {
    backgroundColor: "#F5F5F5",
  },
  table: {
    border: "1pt solid #666666",
    borderTopWidth: 0,
    borderLeftWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    border: "1pt solid #666666",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    padding: 4,
    flex: 1,
    fontSize: 8,
  },
});

export default PDFDocTable;
