import { AppService } from '../../../AppService';

export const CurrencyService = AppService.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query({
        query: (params = {page: 1, limit: 10}) => ({
            url: "/accounting/currencies",
            method: "GET",
            params: params,
        }),
        providesTags: ['App', 'Currencies', 'GetCurrencies'],
    }),
    getCurrency: builder.query({
        query: (id) => ({
            url: `/accounting/currencies/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Currencies', 'GetCurrency'],
    }),
    getCurrencyToUpdate: builder.query({
        query: (id) => ({
            url: `/accounting/currencies/edit/${id}`,
            method: "GET",
        }),
        providesTags: ['App', 'Currencies', 'GetCurrencyToUpdate'],
    }),
    createCurrency: builder.mutation({
        query: (data = {}) => ({
            url: `/accounting/currencies/save${data.id ? ("/" + data.id) : ""}`,
            method: "POST",
            data: data,
        }),
       invalidatesTags: ['Currencies'],
    }),
    deleteCurrency: builder.mutation({
        query: (id) => ({
            url: `/accounting/currencies/${id}`,
            method: "DELETE",
        }),
       invalidatesTags: ['GetCurrencies'],
    }),
  }),
});

export const {
    useGetCurrenciesQuery,
    useGetCurrencyQuery,
    useGetCurrencyToUpdateQuery,
    useCreateCurrencyMutation,
    useDeleteCurrencyMutation,
} = CurrencyService;
