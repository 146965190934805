import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Table } from "../../../components/table";

import {
  DateInput,
  SelectInput,
} from "../../../components/input";

import { formatDateForDb, getDatesOptions, getModulePermissions, numberFormat, round} from "../../../utils/helpers/helpers";
import { clearFilters, setFilters as setFilter } from "../../../states/FiltersState";
import { clearAlert, setAlert } from "../../../states/AlertState";
import { clearLocalFilters, setLocalFilters } from "../../../states/FiltersLocalState";
import { capitalize } from './../../../utils/helpers/helpers';
import ExpenseList from "../../expenses/ExpenseList";
import { useGetTrialBalanceQuery } from "../../../services/accounting/AccountingReportService";
import moment from "moment";
import { Modal } from "../../../components/modal";
interface TrialBalanceProps {
  exportTitle?: string,
  vehicle?: any,
  employee?: any,
  vendor?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const TrialBalance = (props: TrialBalanceProps) => {
  const user = useSelector((state: any) => state.userState);
  const setFilters: any = !props.useLocalFilters ? setFilter : setLocalFilters;
  const filters = useSelector((state: any) => !props.useLocalFilters ? state.filtersState : state.filtersLocalState);
  const dispatch = useDispatch();

  const modal = useRef<any>();

  const { data, isLoading, isFetching, status, isSuccess, isError, error } = useGetTrialBalanceQuery({
    ...filters,
  });

  const [reportData, setReportData] = useState<any[]>([]);

  const [accountingMethods] = useState([
    {label: "Accrual", value: "accrual"},
    {label: "Cash", value: "cash"},
  ]);
  
  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  );

  const getTitleObject = (title: string, level: string = "") => {
    return {
      account_name: capitalize(title),
      isTitle: true,
      [`isSection${capitalize(level)}Title`]: true,
    }
  }

  const getTotalObject = (totalObject: {debit: any, credit: any}, label: string, level: string = "") => {
    return {
      account_name: capitalize(label),
      account_code: "",
      net_debit: totalObject.debit,
      net_credit: totalObject.credit,
      [`isSection${capitalize(level)}Total`]: true,
    }
  }

  const getCategoryAccounts = (category: any) => {
    let accounts = data?.accounts?.filter((account: any) => account.account_category_id == category) || [];

    return accounts;
  }

  const getTotal = () => {
    let totalObject: any = {};
    ["debit", "credit"]?.forEach((field: any) => {
      totalObject[field] = (data?.accounts || []).reduce((acc: number, item: any) => {
          return acc + (field == "debit" ? parseFloat(item.net_debit) : parseFloat(item.net_credit));
      }, 0);
    })

    totalObject.debit = totalObject.debit?.toFixed(2);
    totalObject.credit = totalObject.credit?.toFixed(2);
    return totalObject;
  }

  const populateData = () => {
    setReportData([
      getTitleObject("Assets", "Minor"),
      ...getCategoryAccounts(1),
      getTitleObject("Liabilities", "Minor"),
      ...getCategoryAccounts(2),
      getTitleObject("Equities", "Minor"),
      ...getCategoryAccounts(3),
      getTitleObject("Income", "Minor"),
      ...getCategoryAccounts(4),
      getTitleObject("Expense", "Minor"),
      ...getCategoryAccounts(5),

      getTotalObject(getTotal(), "Total"),
    ]);
  }

  useEffect(() => {
    populateData();
  }, [data])

  useEffect(() => {
    isError && dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError])

  useEffect(() => {
    (isLoading || isFetching) && dispatch(setAlert({type: "progress"}));
  }, [isLoading, isFetching])

  useEffect(() => {
    (isSuccess && status === "fulfilled") && dispatch(clearAlert());
  }, [isSuccess, status])

  useEffect(() => {
    return () => {
      !props.useLocalFilters ? dispatch(clearFilters()) : dispatch(clearLocalFilters());
    }
  }, [])

  const columns = [
    {
      label: "Account",
      name: "account_name",
      customRender: true,
      valueGetter: (item: any) =>  item.parent_account_name ?
        `${capitalize(item.parent_account_name)} - (${capitalize(item.account_name)})` :
        `${capitalize(item.account_name)}${item.child_accounts_count > 0 ? ` - (Parent)` : ""}`,
      colSpan: (item: any, index: number, column: any) => item.isTitle && 4,
      className: (item: any, index: number, column: any) => item.isSectionTitle ? "bg-primary text-large text-center py-2" : (item.isSectionSubTitle ? "bg-d text-medium py-2" : (item.isSectionMinorTitle ? "bg-secondary text-center" : "")),
      flex: (item: any, index: number, column: any) => item.isSectionTitle ? 4 : 1,
    },
    // {label: "Account Code", name: "account_code", dataClassName: "text-italic secondary-text"},
    {label: "Net Debit", name: "net_debit", money: true, customRender: true},
    {label: "Net Credit", name: "net_credit", money: true, customRender: true},
  ]

  return (
    <>
      <div className="row shadow bg-white p-2 mb-1">
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Balance Date"
            block={true}
            options={getDatesOptions()}
            value={getDatesOptions().find(date => date.name === filters.annually_report_date_name)}
            onChange={(value) => dispatch(setFilters({
              annually_report_from_date: value?.name !== "custom" ? (value?.name === "previous_year" ? moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD HH:mm:ss") : moment().startOf("year").format("YYYY-MM-DD HH:mm:ss")): filters.annually_report_from_date,
              annually_report_to_date: value?.name !== "custom" ? value?.to_date : filters.annually_report_to_date,
              annually_report_date_name: value?.name,
            }))}
          />
        </div>
        {filters.annually_report_date_name === "custom" &&
        <div className="col-12 col-md-3 col-sm-4">
          <DateInput
            renderEndIcon={() => (
              <span className="material-icons">calendar_today</span>
            )}
            placeholder="Custom Balance Date"
            block={true}
            value={new Date(filters.annually_report_to_date)}
            onChange={(value) => {
              dispatch(setFilters({
                annually_report_from_date: formatDateForDb(moment(value).startOf("year").format("YYYY-MM-DD HH:mm:ss")),
                annually_report_to_date: formatDateForDb(value),
              }))
            }}
          />
        </div>}
        <div className="col-12 col-md-3 col-sm-4">
          <SelectInput
            placeholder="Accounting Method"
            block={true}
            options={accountingMethods}
            value={accountingMethods.find(method => method.value === (filters.accounting_method || "accrual"))}
            onChange={(value) => dispatch(setFilters({
              accounting_method: value?.value,
            }))}
          />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
            <div>
              <Table
                loading={isLoading}
                title={<span>Trial Balance ({capitalize(filters.accounting_method)} Basis) - <span className="text-small">AS of {moment(filters.annually_report_to_date).format("MMM DD, YYYY")}</span></span>}
                exportTitle={props.exportTitle}
                downloadFileName={props.exportTitle || `Trial Balance (${capitalize(filters.accounting_method)} Basis)- AS of ${moment(filters.annually_report_to_date).format("MMM DD, YYYY")}`}
                hideIndex
                noSearch
                // exportFormats={["pdf"]}
                columns={columns}
                items={reportData || []}
                customRenders={[
                  {
                    columnName: "account_name",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-left" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.labelClassName || ""),
                    render: (item) => item.parent_account_name ?
                    <span>{capitalize(item.parent_account_name)} - (<span className="text-bold">{capitalize(item.account_name)}</span>)</span> :
                    <span>
                      <span>{capitalize(item.account_name)}</span>
                      <span>
                        {item.child_accounts_count > 0 && <span> - (<span className="text-bold">Parent</span>)</span>}
                      </span>
                    </span>
                  },
                  {
                    columnName: "net_debit",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.net_debit, 2, true)}</span>
                  },
                  {
                    columnName: "net_credit",
                    getClassName: (item) => (item.isSectionMinorTotal ? "text-bold" : "") + (item.isSectionSubTotal ? "text-bold bg-info text-center" : "") + (item.isSectionTotal ? "text-bold bg-d text-large" : "") + (item.valueClassName || ""),
                    render: (item) => <span>{numberFormat(item.net_credit, 2, true)}</span>
                  },
                ]}
              />
            </div>
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TrialBalance;
