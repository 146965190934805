import { ExpenseCategoryItemList } from "../../settings/expenses";
interface ExpenseItemsProps {
  exportTitle?: string,
  vendor?: any,
  useLocalFilters?: boolean,
  [rest: string]: any,
}

const ExpenseItems = (props: ExpenseItemsProps) => {

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <ExpenseCategoryItemList
                title="Expense Items"
                vendor={props.vendor}
                useLocalFilters={props.useLocalFilters}
                exportTitle={props.exportTitle}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExpenseItems;
