import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { useGetTripQuery } from "../../services/trips/TripService";
import PaymentList from "../payments/PaymentList";
import ExpenseList from "../expenses/ExpenseList";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from './../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import TripCreate from "./TripCreate";

interface TripDetailsProps {
  trip?: any,
}

const TripDetails = (props: TripDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { tripID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetTripQuery(tripID);

  const permission = useMemo(
    () => getModulePermissions("trips", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  const editTrip = (trip: any) => {
    modal.current?.show(
      "Edit Trip Info",
      (<TripCreate
        tripID={trip.trip_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let trip = data?.trip;
    let trip_status = trip?.status == 0 ? "Completed" : (trip?.status == 1 ? "Ongoing" : (trip?.status == 2 ? "Yet to start" : (trip?.status == 3 ? "Yet to Assign" : "---")));
    let trip_status_class = trip?.status == 0 ? "bg-success" : (trip?.status == 1 ? "bg-secondary" : (trip?.status == 2 ? "bg-warning" : (trip?.status == 3 ? "bg-danger" : "bg-light")));
    let container_size = trip?.container_size == 1 ? "20ft" : (trip?.container_size == 2 ? "40ft" : (trip?.container_size == 3 ? "40ft HC" : (trip?.container_size == 4 ? "45ft HC" : "---")));
    let trip_payment_status_class = trip?.payment_status === "Paid" ? "bg-success" : (trip?.payment_status == "Unpaid" ? "bg-danger" : "bg-warning")

    setSummary({
      title: trip?.route,
      summary: [
        {value: trip?.trip_type == 1 ? 'Singletrip' : 'Roundtrip'},
        {value: trip_status, className: `badge text-xsmall ${trip_status_class}`},
        {value: trip?.payment_status, className: `badge text-xsmall ${trip_payment_status_class}`},
      ],
      details: [
        {label: "Distance (Km)", value: `${numberFormat(trip?.distance || 0)}${(trip?.trip_type == 1 ? '' : ' X 2')}`},
        {label: "Income", value: numberFormat(trip?.total_income || 0)},
        {label: "Discount", value: numberFormat(trip?.total_discount || 0)},
        {label: "Expenses", value: numberFormat(trip?.total_expense || 0)},
        {label: (trip?.total_income - trip?.total_discount) >= trip?.total_expense ? 'Profit' : 'Loss', value: numberFormat(Math.abs((trip?.total_income || 0) -(trip?.total_discount || 0) - (trip?.total_expense || 0)))},
      ]
    });

    setBasicInfo([
      {
        label: "File Number",
        value: trip?.file_number ? trip?.file_number : '---',
      },
      {
        label: "Cargo(s)",
        value: trip?.cargos,
      },
      {
        label: "Cargo Weight (Kg)",
        value: numberFormat(trip?.cargo_weight || 0),
      },
      {
        label: "Cargo Number",
        value: trip?.cargo_number ? trip?.cargo_number : '---',
      },
      {
        label: "Container Number",
        value: trip?.container_number ? trip?.container_number : '---',
      },
      {
        label: "Container Size",
        value: container_size,
      },
      {
        label: "Customer",
        value: trip?.customer_name,
      },
      {
        label: "Shipping Address",
        value: trip?.shipping_physical_address ? trip?.shipping_physical_address : '---',
      },
      {
        label: "Consignee Name",
        value: trip?.consignee_name ? trip?.consignee_name : '---',
      },
      {
        label: "Consignee Contact",
        value: trip?.consignee_contact ? trip?.consignee_contact : '---',
      },
      {
        label: "Route",
        value: trip?.route,
      },
      {
        label: "Trip Type",
        value: trip?.trip_type == 1 ? 'Singletrip' : 'Roundtrip',
      },
      {
        label: "Vehicle",
        value: trip?.vehicle_registration_number,
      },
      {
        label: "Driver",
        value: trip?.driver_name,
      },
      {
        label: "Trip Status",
        value: trip_status,
        className: `badge text-xsmal ${trip_status_class}`
      },
      {
        label: "Vessel Name",
        value: trip?.vessel_name ? trip?.vessel_name : '---',
      },
      {
        label: "Vessel ETA",
        value: trip?.vessel_eta ? trip?.vessel_eta : '---',
      },
      {
        label: "Discharge Date",
        value: trip?.discharge_date ? trip?.discharge_date : '---',
      },
      {
        label: "Loading date",
        value: trip?.loading_date ? trip?.loading_date : '---',
      },
      {
        label: "Loading Place",
        value: trip?.loading_place ? trip?.loading_place : '---',
      },
      {
        label: "Bill of Lading Number",
        value: trip?.bol_number ? trip?.bol_number : '---',
      },
      {
        label: "OBL+CI+PL received date",
        value: trip?.documents_received_date ? trip?.documents_received_date : '---',
      },
      {
        label: "Departure Date",
        value: trip?.start_date,
      },
      {
        label: "First Border Arrival date",
        value: trip?.first_border_arrival_date ? trip?.first_border_arrival_date : '---',
      },
      {
        label: "First Border Departure date",
        value: trip?.first_border_departure_date ? trip?.first_border_departure_date : '---',
      },
      {
        label: "Second Border Arrival date",
        value: trip?.second_border_arrival_date ? trip?.second_border_arrival_date : '---',
      },
      {
        label: "Second Border Departure date",
        value: trip?.second_border_departure_date ? trip?.second_border_departure_date : '---',
      },
      {
        label: "Arrival Date At Destination",
        value: trip?.end_date ? trip?.end_date : '---',
      },
      {
        label: "OffLoading Place",
        value: trip?.offloading_place ? trip?.offloading_place : '---',
      },
      {
        label: "Waiting Time (Hours)",
        value: trip?.waiting_time ? trip?.waiting_time : '---',
      },
      {
        label: "Offloading Date",
        value: trip?.offloading_date ? trip?.offloading_date : '---',
      },
      {
        label: "Vehicle Detention Days",
        value: trip?.detention_time ? trip?.detention_time : '---',
      },
      {
        label: "Departure Date After Offloading",
        value: trip?.after_offloading_departure_date ? trip?.after_offloading_departure_date : '---',
      },
      {
        label: "Discharge Date",
        value: trip?.discharge_date ? trip?.discharge_date : '---',
      },
      {
        label: "Payment Status",
        value: trip?.payment_status,
        className: `badge text-xsmal ${trip_payment_status_class}`
      },
      {
        label: "Freight Rate",
        value: numberFormat(trip?.freight_rate || 0),
        className: "text-bold"
      },
      {
        label: `Discount${trip?.discount_type === "percent" ? `(${numberFormat(trip.discount || 0)}%)` : ""}`,
        value: numberFormat(trip?.total_discount),
      },
      ...(
        trip?.charges
        ?.filter((charge: any) => charge.charge_category === "tax")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Tax`,
          value: numberFormat(charge.charge_type === "percent" ? numberFormat(trip?.freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
        })) || []
      ),
      ...(
        trip?.charges
        ?.filter((charge: any) => charge.charge_category === "other")
        ?.map((charge: any) => ({
          label: `${charge.charge_name}${charge.charge_type === "percent" ? `(${numberFormat(charge.charge_amount || 0)}%)` : ""} - Charge`,
          value: numberFormat(charge.charge_type === "percent" ? numberFormat(trip?.freight_rate * (charge.charge_amount / 100)) : numberFormat(charge.charge_amount)),
        })) || []
      ),
      {
        label: "Amount To Be Payed",
        value: numberFormat(trip?.payment_amount),
        className: "text-bold"
      },
      {
        label: "Amount Paid",
        value: numberFormat(trip?.total_payment),
      },
      {
        label: "Amount Due",
        value: numberFormat(trip?.payment_amount_due),
        className: "text-bold text-large"
      },
      {
        label: "Return Trip",
        isSectionTitle: true,
      },
      {
        label: "Loading Place Arrival Date",
        value: trip?.return_loading_place_arrival_date ? trip?.return_loading_place_arrival_date : '---',
      },
      {
        label: "Loading date",
        value: trip?.return_loading_date ? trip?.return_loading_date : '---',
      },
      {
        label: "Loading Place",
        value: trip?.return_loading_place ? trip?.return_loading_place : '---',
      },
      {
        label: "Documents Received Date",
        value: trip?.return_documents_received_date ? trip?.return_documents_received_date : '---',
      },
      {
        label: "Departure Date",
        value: trip?.return_start_date ? trip?.return_start_date : '---',
      },
      {
        label: "First Border Arrival date",
        value: trip?.return_first_border_arrival_date ? trip?.return_first_border_arrival_date : '---',
      },
      {
        label: "First Border Departure date",
        value: trip?.return_first_border_departure_date ? trip?.return_first_border_departure_date : '---',
      },
      {
        label: "Second Border Arrival date",
        value: trip?.return_second_border_arrival_date ? trip?.return_second_border_arrival_date : '---',
      },
      {
        label: "Second Border Departure date",
        value: trip?.return_second_border_departure_date ? trip?.return_second_border_departure_date : '---',
      },
      {
        label: "Arrival Date At Destination",
        value: trip?.return_end_date ? trip?.return_end_date : '---',
      },
      {
        label: "Offloading Date",
        value: trip?.return_offloading_date ? trip?.return_offloading_date : '---',
      },
      {
        label: "Empty Container Return Date",
        value: trip?.return_empty_container_return_date ? trip?.return_empty_container_return_date : '---',
      },
      {
        label: "Current Trip Status",
        isSectionTitle: true,
      },
      // {
      // 	label: "Return Date",
      // 	value: trip?.return_date ? trip?.return_date : '---',
      // },
      {
        label: "Current Position",
        value: trip?.current_position ? trip?.current_position : '---',
      },
      {
        label: "Current Status Comment",
        value: trip?.comment ? trip?.comment : '---',
      },
    ]);
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Trip Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Trip"
            permission={permission}
            details={basicInfo}
            detailsEditAction={
              <button
                className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
                onClick={() =>
                  permission?.update ?
                  editTrip(data?.trip) :
                  onNoAccess("edit", "trip")
                }
              >
                Edit Trip
              </button>
            }
            summary={summary}
            tabs={[
              <PaymentList
                key={2}
                title="Payments"
                exportTitle="Trip Payments"
                trip={data?.trip}
                paymentTransaction={data?.trip?.payment_transaction_id && data?.trip}
                from="trip"
                useLocalFilters
              />,
              <ExpenseList
                key={3}
                title="Expenses"
                className={!hasPermission("expenses", user.permissions) && "disabled"}
                onClick={!hasPermission("expenses", user.permissions) ? () => onNoAccess("access", "expenses") : undefined}
                exportTitle="Trip Expenses"
                trip={data?.trip}
                from="trip"
                useLocalFilters
              />
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default TripDetails;
