import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../components/modal";
import { useGetCustomerQuery } from "../../services/customers/CustomerService";
import { useParams } from "react-router-dom";
import { ItemDetails } from "../../components/layout/item-details";
import { getModulePermissions, getNoPermissionMessage, hasPermission, numberFormat } from '../../utils/helpers/helpers';
import { useDispatch, useSelector } from "react-redux";
import { clearAlert, setAlert } from "../../states/AlertState";
import CustomerCreate from "./CustomerCreate";
import TripList from "../trips/TripList";
import RouteList from "../routes/RouteList";
import CargoList from "../cargos/CargoList";

interface CustomerDetailsProps {
  customer?: any,
}

const CustomerDetails = (props: CustomerDetailsProps) => {
  const user = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();
  const { customerID } = useParams();

  const modal = useRef<any>();

  const [basicInfo, setBasicInfo] = useState<any>([]);
  const [summary, setSummary] = useState<any>([]);

  const { data, isLoading, isError, error, isSuccess } = useGetCustomerQuery(customerID);

  const permission = useMemo(
    () => getModulePermissions("customers", user?.permissions),
    [user]
  )

  const onNoAccess = (action?: string, item?: string) => {
		dispatch(setAlert({type: "error", message: getNoPermissionMessage(action, item)}));
	}

  let editButton = (
    <button
      className={`btn bg-secondary mt-3 ${!permission?.update && "disabled"}`}
      onClick={() =>
        permission?.update ?
        editCustomer(data?.customer) :
        onNoAccess("edit", "customer")
      }
    >
      Edit Customer
    </button>
  );

  const editCustomer = (customer: any) => {
    modal.current?.show(
      "Edit Customer Info",
      (<CustomerCreate
        customerID={customer.customer_id}
        modal={modal}
      />),
    );
  }

  useEffect(() => {
    let customer = data?.customer;
    let customer_status = customer?.is_active == 1 ? "Active" : "Inactive";
    let customer_status_class = customer?.is_active == 1 ? "bg-success" : "bg-danger";

    setSummary({
      title: customer?.first_name,
      summary: [
        {value: customer?.phone_number},
        {value: customer_status, className: `badge text-xsmall ${customer_status_class}`},
      ],
      details: [
        {label: "Trips", value: numberFormat(customer?.total_trips || 0)},
        {label: "Income", value: numberFormat(customer?.total_income || 0)},
        {label: "Routes", value: numberFormat(customer?.total_routes || 0)},
        {label: "Cargos", value: numberFormat(customer?.total_cargos || 0)},
      ]
    });

    setBasicInfo([
      {
        label: "Name",
        value: customer?.first_name,
      },
      {
        label: "Phone No",
        value: customer?.phone_number,
      },
      {
        label: "Phone No. 2",
        value: customer?.phone_number_2 || '---',
      },
      {
        label: "Email",
        value: customer?.email || '---',
      },
      {
        label: "Address",
        value: customer?.physical_address,
      },
      {
        label: "Status",
        value: customer_status,
        className: `badge text-xsmal ${customer_status_class}`
      },
    ]);
  }, [data]);

  useEffect(() => {
    isError &&  dispatch(setAlert({type: "error", message: 'errorMessage' in error ? error.errorMessage : error.message}));
  }, [isError]);

  useEffect(() => {
    isLoading && dispatch(setAlert({type: "progress"}));
  }, [isLoading])

  useEffect(() => {
    isSuccess && dispatch(clearAlert());
  }, [isSuccess]);

  return (
    <>
      <div className="card shadow-sm">
        <div className="card-header">Customer Details</div>
        <div className="card-body">
          <ItemDetails
            itemName="Customer"
            permission={permission}
            details={basicInfo}
            detailsEditAction={editButton }
            summary={summary}
            tabs={[
              <TripList
                key={2}
                title="Trips"
                className={!hasPermission("trips", user.permissions) && "disabled"}
                onClick={!hasPermission("trips", user.permissions) ? () => onNoAccess("access", "trips") : undefined}
                exportTitle={`Customer(${data?.customer?.first_name}) Trips`}
                customer={data?.customer}
                useLocalFilters
              />,
              <RouteList
                key={3}
                title="Routes"
                className={!hasPermission("routes", user.permissions) && "disabled"}
                onClick={!hasPermission("routes", user.permissions) ? () => onNoAccess("access", "routes") : undefined}
                exportTitle={`Customer(${data?.customer?.first_name}) Routes`}
                customer={data?.customer}
                useLocalFilters
              />,
              <CargoList
                key={3}
                title="Cargos"
                className={!hasPermission("cargos", user.permissions) && "disabled"}
                onClick={!hasPermission("cargos", user.permissions) ? () => onNoAccess("access", "cargos") : undefined}
                exportTitle={`Customer(${data?.customer?.first_name}) Cargos`}
                customer={data?.customer}
                useLocalFilters
              />,
            ]}
          />
        </div>
      </div>
      <Modal ref={modal} />
    </>
  );
}

export default CustomerDetails;
